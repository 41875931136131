import Dialog from "@components/dialog/Dialog";
import BindingContext, { createBindingContext } from "@odata/BindingContext";
import { IEntityBase } from "@odata/GeneratedEntityTypes";
import { WithOData, withOData } from "@odata/withOData";
import {
    AdditionalSubmissionCorrectionReasonPath,
    AdditionalSubmissionDateCorrectionDiscoveredPath,
    AdditionalSubmissionPath,
    AdditionalSubmissionReferenceNumberOfNoticePath,
    REFERENCE_NUMBER_PLACEHOLDER
} from "@pages/electronicSubmission/AdditionalSubmissionDialog.utils";
import { IDefinition, IGetDefinitionOptionalTranslation } from "@pages/PageUtils";
import i18next from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { BasicInputSizes, FieldType, ValidatorType, ValueType } from "../../enums";
import { getUtcDate } from "../../types/Date";
import { FormViewForExtend } from "../../views/formView/FormView";
import PlugAndPlayForm from "../../views/formView/PlugAndPlayForm";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";

export interface IAdditionalSubmissionDialogResult {
    dateCorrectionDiscovered: Date;
    correctionReason: string;
    referenceNumber: string;
}

interface IAdditionalSubmissionDialogEntity extends IEntityBase {
    [AdditionalSubmissionDateCorrectionDiscoveredPath]?: Date;
    [AdditionalSubmissionCorrectionReasonPath]?: string;
    [AdditionalSubmissionReferenceNumberOfNoticePath]?: string;
}

interface IProps extends WithTranslation, WithOData {
    title: string;
    showReason?: boolean;
    showReferenceNumber?: boolean;
    onConfirm: (result: IAdditionalSubmissionDialogResult) => void;
    onCancel: () => void;
}

class AdditionalSubmissionDialog extends React.Component<IProps> {
    _formRef = React.createRef<FormViewForExtend<IAdditionalSubmissionDialogEntity>>();

    handleConfirm = async (): Promise<void> => {
        await this._formRef.current.props.storage.validateAll();
        this._formRef.current.props.storage.refresh();

        if (!this._formRef.current.props.storage.isAnyError()) {
            const entity = this._formRef.current.props.storage.data.entity;

            this.props.onConfirm({
                dateCorrectionDiscovered: entity[AdditionalSubmissionDateCorrectionDiscoveredPath],
                correctionReason: entity[AdditionalSubmissionCorrectionReasonPath],
                referenceNumber: entity[AdditionalSubmissionReferenceNumberOfNoticePath]
            });
        }
    };

    handleCancel = (): void => {
        this.props.onCancel();
    };

    getBindingContext = (): BindingContext => {
        return createBindingContext(AdditionalSubmissionPath, this.props.oData.getMetadata());
    };

    render() {
        return (
            <Dialog
                title={this.props.title}
                onConfirm={this.handleConfirm}
                onClose={this.handleCancel}
                footer={<ConfirmationButtons
                    onConfirm={this.handleConfirm}
                    onCancel={this.handleCancel}
                    confirmText={this.props.t("Common:General.Create")}
                />}
            >
                <PlugAndPlayForm
                    getDef={getDefinition({
                        showReason: this.props.showReason,
                        showReferenceNumber: this.props.showReferenceNumber
                    })}
                    t={this.props.t}
                    bindingContext={this.getBindingContext()}
                    formRef={this._formRef}
                    formView={AdditionalSubmissionDialogFormViewWrapped}
                    formViewProps={{
                        formProps: {
                            isSimple: true,
                            renderScrollbar: false
                        }
                    }}
                />
            </Dialog>
        );
    }
}

export default withTranslation(["ElectronicSubmission", "Common"])(withOData(AdditionalSubmissionDialog));

interface IGetDefinitionArgs {
    showReason: boolean;
    showReferenceNumber: boolean;
}

const getDefinition = ({ showReason, showReferenceNumber }: IGetDefinitionArgs): IGetDefinitionOptionalTranslation => {
    return (context: IAppContext) => {
        const def: IDefinition = {
            entitySet: AdditionalSubmissionPath,
            form: {
                id: "AdditionalSubmissionDialogSpecialForm",
                groups: [{
                    id: "Main",
                    rows: [
                        [{ id: AdditionalSubmissionDateCorrectionDiscoveredPath }]
                    ]
                }],
                fieldDefinition: {
                    [AdditionalSubmissionDateCorrectionDiscoveredPath]: {
                        width: BasicInputSizes.M,
                        label: i18next.t("ElectronicSubmission:AdditionalSubmissionDialog.Date"),
                        isRequired: true,
                        type: FieldType.Date,
                        valueType: ValueType.Date,
                        validator: {
                            type: ValidatorType.Date
                        },
                        defaultValue: getUtcDate()
                    }
                },
                translationFiles: ["ElectronicSubmission"]
            }
        };

        if (showReason) {
            def.form.groups[0].rows.push([{ id: AdditionalSubmissionCorrectionReasonPath }]);
            def.form.fieldDefinition[AdditionalSubmissionCorrectionReasonPath] = {
                type: FieldType.EditableText,
                width: BasicInputSizes.M,
                label: i18next.t("ElectronicSubmission:AdditionalSubmissionDialog.Reason"),
                isRequired: true,
                validator: {
                    type: ValidatorType.String,
                    settings: {
                        max: 240
                    }
                }
            };
        }

        if (showReferenceNumber) {
            def.form.groups[0].rows.push([{ id: AdditionalSubmissionReferenceNumberOfNoticePath }]);
            def.form.fieldDefinition[AdditionalSubmissionReferenceNumberOfNoticePath] = {
                width: BasicInputSizes.XL,
                label: i18next.t("ElectronicSubmission:AdditionalSubmissionDialog.ReferenceNumberOfNotice"),
                validator: {
                    type: ValidatorType.String,
                    settings: {
                        length: REFERENCE_NUMBER_PLACEHOLDER.length
                    }
                },
                fieldSettings: {
                    placeholder: REFERENCE_NUMBER_PLACEHOLDER
                }
            };
        }

        return def;
    };
};

class AdditionalSubmissionDialogFormView extends FormViewForExtend<IAdditionalSubmissionDialogEntity> {
    static contextType = AppContext;
}

const AdditionalSubmissionDialogFormViewWrapped = withPermissionContext(AdditionalSubmissionDialogFormView);