import { formatDateToDateString, getWorkDate } from "@components/inputs/date/utils";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { getInfoByPath, ifAll, IFieldInfoProperties, IGetValueArgs, TValidatorFn } from "@components/smart/FieldInfo";
import { AccountDefFormatter, getAccountDef } from "@components/smart/GeneralFieldDefinition";
import { getCollapsedGroupId } from "@components/smart/Smart.utils";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IDependentFieldDef, IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import {
    fetchAndSetItemsByInfo,
    fetchItemsByInfo,
    fetchItemsByPath,
    invalidateItems
} from "@components/smart/smartSelect/SmartSelectAPI";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { getBoundValue, getNestedValue, setNestedValue } from "@odata/Data.utils";
import {
    AccountAssignmentEntity,
    DocumentAccountAssignmentEntity,
    DocumentEntity,
    DocumentVatClassificationEntity,
    DocumentVatClassificationSelectionEntity,
    EntitySetName,
    IAccountAssignmentEntity,
    IAccountEntity,
    IChartedAccountAssignmentEntity,
    IDocumentAccountAssignmentEntity,
    IDocumentAccountAssignmentSelectionEntity,
    IDocumentEntity,
    IDocumentTypeEntity,
    IFiscalYearEntity,
    IPaymentDocumentItemEntity,
    PaymentDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import {
    CompanyPermissionCode,
    DocumentTypeCode,
    GeneralPermissionCode,
    PaymentDocumentItemTypeCode,
    SelectionCode
} from "@odata/GeneratedEnums";
import { BatchRequest } from "@odata/OData";
import { IFormatOptions, prepareQuery } from "@odata/OData.utils";
import { getAgendaCountryCode } from "@pages/companies/Company.shared.utils";
import { memoizedWithCacheStrategy } from "@utils/CacheCleaner";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { isNotDefined, isObjectEmpty, roundToDecimalPlaces } from "@utils/general";
import { logger } from "@utils/log";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import { ValidationError } from "yup";

import {
    BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX,
    CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX,
    DASH_CHARACTER
} from "../../constants";
import {
    BasicInputSizes,
    CacheStrategy,
    FastEntryInputSizes,
    FieldType,
    LabelStatus,
    NavigationSource,
    ValidationErrorType,
    ValidatorType
} from "../../enums";
import { TRecordAny, TValue } from "../../global.types";
import { Model } from "../../model/Model";
import { StorageModel } from "../../model/StorageModel";
import { IValidationError } from "../../model/Validator.types";
import BindingContext, { createPath, IEntity } from "../../odata/BindingContext";
import { FormStorage, IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";
import {
    getFiscalDataCorrespondingToDateAccountingTransaction,
    isMatchingAccount,
    isReceived
} from "../documents/Document.utils";
import { getActiveChartOfAccountsId } from "../fiscalYear/FiscalYear.utils";
import { TFieldDefinition, TFieldsDefinition } from "../PageUtils";
import { reloadAccounts } from "./Account.utils";

export const ACCOUNT_ASSIGNMENT_GROUP_ID = "Assignment";
const ACCOUNT_ASSIGNMENT_COLLAPSED_GROUP_ID = getCollapsedGroupId({ id: "AccountAssignmentSelection/AccountAssignment" });

export const ItemCreateCustomAssignmentBc = BindingContext.localContext("ItemCreateCustomAssignment");
export const CreateCustomAssignmentPath = "##CreateCustomAssignment##";

export const accountAssignmentSelectColumns = [
    {
        id: "ShortName",
        additionalProperties: [
            // properties that need to be set on item selection
            { id: "ChartOfAccounts" },
            { id: "CreditAccount/Name" }, { id: "CreditAccount/Number" }, { id: "CreditAccount/IsClosed" },
            { id: "DebitAccount/Name" }, { id: "DebitAccount/Number" }, { id: "DebitAccount/IsClosed" },
            { id: "ShortName" }
        ]
    },
    {
        id: "Name"
    }
];
export const vatAssignmentFields = ["AccountAssignmentSelection/AccountAssignment/CreditAccount", "AccountAssignmentSelection/AccountAssignment/DebitAccount"];
export const AccountDialogFields = ["DebitAccount", "CreditAccount", "Name", ItemCreateCustomAssignmentBc];

// currently, isWithoutSelectionPrefix is not used anywhere, but keeping for possible future use
const getFieldId = (id: string, isWithoutSelectionPrefix?: boolean) => {
    return `${!isWithoutSelectionPrefix ? "AccountAssignmentSelection/" : ""}AccountAssignment${id ? `/${id}` : ""}`;
};

export const getAccountAssignmentDependentFields = (isLineItem: boolean): IDependentFieldDef[] => {
    return [
        {
            from: { id: "CreditAccount" },
            to: isLineItem ? null : { id: "AccountAssignmentSelection/AccountAssignment/CreditAccount" },
            navigateFrom: isLineItem ? NavigationSource.Itself : NavigationSource.Root
        },
        {
            from: { id: "DebitAccount" },
            to: isLineItem ? null : { id: "AccountAssignmentSelection/AccountAssignment/DebitAccount" },
            navigateFrom: isLineItem ? NavigationSource.Itself : NavigationSource.Root
        },
        {
            from: { id: "ChartOfAccounts" },
            to: isLineItem ? null : { id: "AccountAssignmentSelection/AccountAssignment/ChartOfAccounts" },
            navigateFrom: isLineItem ? NavigationSource.Itself : NavigationSource.Root
        },
        {
            from: { id: "ShortName" },
            to: isLineItem ? null : { id: "AccountAssignmentSelection/AccountAssignment/ShortName" },
            navigateFrom: isLineItem ? NavigationSource.Itself : NavigationSource.Root
        },
        {
            from: { id: "Name" },
            to: isLineItem ? null : { id: "AccountAssignmentSelection/AccountAssignment/Name" },
            navigateFrom: isLineItem ? NavigationSource.Itself : NavigationSource.Root
        }
    ];
};

export const getAccAssignmentFilterWithTypeAndDate = (type: DocumentTypeCode, args: IGetValueArgs, date?: Date): string => {
    const dt = date ?? args.storage.data.entity.DateAccountingTransaction ?? getWorkDate();
    const formattedDate = formatDateToDateString(dt);
    return `DocumentTypeCode eq '${type}' and ChartOfAccounts/FiscalYear/DateStart le ${formattedDate} and ChartOfAccounts/FiscalYear/DateEnd ge ${formattedDate}
            AND DebitAccount/IsActive eq true AND CreditAccount/IsActive eq true`;
};

const accAssignmentFilterWithType = (type: DocumentTypeCode, args: IGetValueArgs): string => {
    return getAccAssignmentFilterWithTypeAndDate(type, args);
};

export const accountAssignmentComparisonFunction = (entity1: IEntity, entity2: IEntity, bc: BindingContext): boolean => {
    const bcParent = bc.getParent(); // (document or item) AccountAssignmentSelection

    const left = getBoundValue({ bindingContext: bcParent, data: entity1, dataBindingContext: bc.getRootParent() });
    const right = getBoundValue({ bindingContext: bcParent, data: entity2, dataBindingContext: bc.getRootParent() });
    let hasSplitDifference = false;

    // Selection Code is dynamic property which is change in the runtime based on additional external data and such is displayed to user
    // assignment to be different has to differ in both CA and DA and a note
    // this can make soma draft stuff not working as orig entity may differ from entity due to the fact,
    // this can be changed in onAfterLoad based on wheather there exists same account assignment
    // Typical case of malfunction: we create "own" AS, then save it, then we create manually same AS and in onafterload "own" -> "copy" and error.
    // if (left?.Selection?.Code !== right?.Selection?.Code) {
    //     return false;
    // }

    if (left?.Selection?.Code === SelectionCode.Split && right?.Selection?.Code === SelectionCode.Split) {
        const leftItem = getBoundValue({
            bindingContext: bcParent.getParent(),
            data: entity1,
            dataBindingContext: bc.getRootParent()
        }) as IPaymentDocumentItemEntity;
        const rightItem = getBoundValue({
            bindingContext: bcParent.getParent(),
            data: entity2,
            dataBindingContext: bc.getRootParent()
        }) as IPaymentDocumentItemEntity;
        hasSplitDifference = leftItem.SplitAccountAssignments?.length !== rightItem.SplitAccountAssignments?.length ||
            leftItem.SplitAccountAssignments?.some(lAss => {
                return !rightItem.SplitAccountAssignments?.find(rAss => {
                    return rAss.CreditAccount?.Id === lAss.CreditAccount?.Id && rAss.DebitAccount?.Id === lAss.DebitAccount?.Id &&
                        rAss.TransactionAmount === lAss.TransactionAmount && rAss.Description === lAss.Description;
                });
            });
    }

    const val1 = "" + left?.AccountAssignment?.CreditAccount?.Id + left?.AccountAssignment?.DebitAccount?.Id;
    const val2 = "" + right?.AccountAssignment?.CreditAccount?.Id + right?.AccountAssignment?.DebitAccount?.Id;

    return val1 === val2 && ((left && right) || (!left && !right)) && !hasSplitDifference;
};

const isVatFieldVisible = (args: IGetValueArgs) => {
    return args.storage.data.entity.AccountAssignmentSelection?.AccountAssignment?.Id !== SelectionCode.None;
};

export const getAdditionalAccAssItems = (skipNoneItem?: boolean): ISelectItem[] => {
    const additionalItems: ISelectItem[] = [
        {
            id: SelectionCode.Own,
            additionalData: {},
            label: i18next.t("Document:AccountAssignment.Own"),
            groupId: "Default"
        }
    ];

    if (!skipNoneItem) {
        additionalItems.unshift({
            id: SelectionCode.None,
            label: i18next.t("Document:AccountAssignment.None"),
            groupId: "Default"
        });
    }

    return additionalItems;
};

export function getShortNameFromAccountNumbers(debitAccountNumber: string, creditAccountNumber: string): string {
    return debitAccountNumber || creditAccountNumber ? `${debitAccountNumber ?? DASH_CHARACTER}/${creditAccountNumber ?? DASH_CHARACTER}` : null;
}

export const accAssignmentFormatter = (val: TValue, args?: IFormatOptions, isDocumentItemAssignment = false): string => {
    let selection: IDocumentAccountAssignmentSelectionEntity;
    let accountAssignment: IDocumentAccountAssignmentEntity;
    if (args.bindingContext) {
        selection = args.storage.getValue(args.bindingContext.getParent());
        accountAssignment = selection?.AccountAssignment;
    } else {
        // case for when used as select formatter
        accountAssignment = args.entity;
    }

    const selectionCode = selection?.Selection?.Code as SelectionCode;
    if (selectionCode === SelectionCode.Split) {
        return i18next.t(`Document:AccountAssignment.${selectionCode}`);
    }

    if ([SelectionCode.Default, SelectionCode.Own].includes(selectionCode)) {
        // default on item
        const isDefault = SelectionCode.Default === selectionCode;
        const defaultSelection = args.entity.AccountAssignmentSelection;
        const defaultShortNameFromAccountNumbers = getShortNameFromAccountNumbers(defaultSelection?.AccountAssignment?.DebitAccount?.Number, defaultSelection?.AccountAssignment?.CreditAccount?.Number);
        const defaultShortName = defaultSelection?.Selection?.Code === SelectionCode.None
            ? i18next.t("Document:AccountAssignment.None")
            : defaultShortNameFromAccountNumbers ?? defaultSelection?.AccountAssignment?.ShortName;

        const shortNameFromAccountNumbers = getShortNameFromAccountNumbers(accountAssignment?.DebitAccount?.Number, accountAssignment?.CreditAccount?.Number);
        const shortName = isDefault ? defaultShortName : shortNameFromAccountNumbers ?? accountAssignment.ShortName;
        const description = i18next.t(`Document:AccountAssignment.${selectionCode}`);

        return shortName ? `${shortName} (${description})` : description;
    }

    const isSpecial = [SelectionCode.Default, SelectionCode.Own, SelectionCode.None]
        .includes(selectionCode);

    if (accountAssignment && !!accountAssignment?.ShortName && !isSpecial) {
        return isDocumentItemAssignment ? accountAssignment.ShortName : `${accountAssignment.ShortName} - ${accountAssignment.Name}`;
    }

    return selectionCode === SelectionCode.None ? i18next.t("Document:AccountAssignment.None") : "";
};

export const accAssignmentItemFormatter = (val: TValue, args?: IFormatOptions): string => {
    return accAssignmentFormatter(val, args, true);
};

interface IChoaArgs {
    transactionDate?: Date;
    datePath?: string;
    fallbackToActiveChOA?: boolean;
}

/** Returns CoA id based on currently selected DateAccountingTransaction, or current active CoA */
export const getCurrentCoAId = (args: IGetValueArgs, options?: IChoaArgs): number => {
    const { entity, bindingContext } = args.storage?.data || {};

    // Some entities may have ChartOfAccounts relation saved on the entity and e.g. FixedAsset can have date in the past
    // before first configured FY, so we fall back for the oldest active FY -> date not match,
    // so we need to keep the preference to selected ChartOfAccounts->Id
    if (entity.ChartOfAccounts?.Id) {
        return entity.ChartOfAccounts.Id;
    }

    const _getDefault = () => {
        return options?.fallbackToActiveChOA === false ? null : getActiveChartOfAccountsId(args.context ?? args.storage.context);
    };


    // Priority was changed, now date has higher priority then entity FiscalYear (as it makes 0 sense to take it from entity, if i want ChoA based on Date)
    // this swap can be reason of some mistakes tho
    if (options?.transactionDate || options?.datePath) {
        let transactionDate = options?.transactionDate;
        if (!transactionDate) {
            transactionDate = getBoundValue({
                bindingContext: bindingContext.navigate(options.datePath),
                data: entity,
                dataBindingContext: bindingContext
            });
        }

        // Question is whether take getActiveChartOfAccountsId if we dont find fiscal year to given date
        // i guess it sould return null ---> open question for the future
        const fiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(args.storage as StorageModel, transactionDate);
        return isObjectEmpty(fiscalData.fiscalYear) ? _getDefault() : fiscalData.fiscalYear?.ChartOfAccounts.Id;
    } else {
        return entity?.FiscalYear?.ChartOfAccounts?.Id ?? _getDefault();
    }
};

export const getChoAIdBasedOnDate = (storage: StorageModel, date: Date, useDefault = true) => {
    const fiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(storage as StorageModel, date);
    return isObjectEmpty(fiscalData.fiscalYear) && useDefault ? getActiveChartOfAccountsId(storage.context) : fiscalData.fiscalYear?.ChartOfAccounts?.Id;
};

interface IFetchAccounts {
    storage: Model;
    choaId: number;
    filter?: string;
}

const getAccountsMemoized = memoizedWithCacheStrategy<IFetchAccounts, IEntity[]>(
    async (args: IFetchAccounts) => {
        const queryBc = new BindingContext({
            metadata: args.storage.oData.metadata,
            path: `ChartsOfAccounts(${args.choaId})/Accounts`
        });

        let filter = "IsActive eq true";

        const query = prepareQuery({
            oData: args.storage.oData,
            bindingContext: queryBc,
            fieldDefs: [{ id: "Id" }, { id: "Name" }, { id: "Number" }]
        });

        if (args.filter) {
            filter += ` AND ${args.filter}`;
        }

        query.filter(filter);

        try {
            const result = (await query.fetchData<IEntity[]>()) ?? { value: [] };
            return result.value;
        } catch (error) {
            logger.error("error in fetch accounts", error);
            return [];
        }
    },
    (args: IFetchAccounts) => {
        return args.choaId?.toString();
    }
);


export const loadAccountsBasedOnDate = async (storage: StorageModel, date: Date, filter?: string) => {
    const choaId = getChoAIdBasedOnDate(storage, date);

    return await getAccountsMemoized({ storage, choaId, filter }, CacheStrategy.View);
};

export const getDocAccountEntitySet = (options: IChoaArgs) => {
    return (args: IGetValueArgs): string => {
        const coaId = getCurrentCoAId(args, options);

        return coaId && `ChartsOfAccounts(${coaId})/Accounts`;
    };
};

export const generalAccountProps = (path?: string, coADatePath?: string): IFieldInfoProperties => {
    const def = getAccountDef(path);

    return {
        ...def,
        customizationData: {
            useForCustomization: isNotTaxEvidence
        },
        filter: {
            select: "IsActive eq true"
        },
        fieldSettings: {
            ...def?.fieldSettings,
            entitySet: getDocAccountEntitySet({
                datePath: coADatePath
            })
        },
        validator: {
            type: ValidatorType.Custom,
            settings: {
                customValidator: accountIsClosedValidator
            }
        }
    };
};

export const getSingleAccountFieldDef = (options: IChoaArgs = {}): TFieldDefinition => {
    const commonDef = generalAccountProps();
    return {
        ...commonDef,
        fieldSettings: {
            ...commonDef.fieldSettings,
            entitySet: getDocAccountEntitySet({
                ...options,
                fallbackToActiveChOA: options.fallbackToActiveChOA ?? false
            })
        },
        formatter: AccountDefFormatter
    };
};

export const isVatChildRequired = (args: IGetValueArgs): boolean => {
    const value = args.storage.getValueByPath("AccountAssignmentSelection/Selection");
    return value?.Code !== undefined && value?.Code !== SelectionCode.None;
};

const isOwnAccountAssignmentSelection = (args: IGetValueArgs) => {
    return args.storage.data.entity.AccountAssignmentSelection?.AccountAssignment?.Id === SelectionCode.Own;
};

const userHasTemplateManagementPermissions = (args: IGetValueArgs): boolean => {
    return args.context?.getGeneralPermissions().has(GeneralPermissionCode.CommonSettingsManagement);
};

const getAdditionalLineAccAssItems = (addDefault = true) => {
    const items = getAdditionalAccAssItems();
    if (addDefault) {
        items.unshift({
            id: SelectionCode.Default,
            label: i18next.t("Document:AccountAssignment.Default"),
            groupId: "Default"
        });
    }
    return items;
};

const hasOwnSelection = ({ storage, bindingContext }: IGetValueArgs): boolean => {
    const { collectionBindingContext } = bindingContext.splitByCollectionPath();
    const selection = storage.getValue(collectionBindingContext.navigate("AccountAssignmentSelection/Selection/Code"));
    return selection === SelectionCode.Own;
};

const defaultAccountAssignmentValidator: TValidatorFn = (value, args): boolean => {
    if (value === SelectionCode.Default) {
        const doc = args.storage.data.entity as IDocumentEntity;
        const selectionCode = doc.AccountAssignmentSelection?.Selection?.Code;
        return !!selectionCode;
    }
    return true;
};

const accountIsClosedValidator: TValidatorFn = (value, args) => {
    const { storage, bindingContext } = args;
    const info = storage.getInfo(bindingContext);
    const items = info.fieldSettings.items ?? [];
    const selected = items.find(item => item.id === value);
    const Account = selected?.additionalData as IAccountEntity;
    if (Account?.IsClosed) {
        const num = Account.Number;
        return new ValidationError(i18next.t("Document:AccountAssignment.AccountClosed", { num }), false, args.bindingContext.getNavigationPath(false));
    }
    return true;
};

export const accountsAreClosedValidator: TValidatorFn = (value, args) => {
    const { storage, bindingContext } = args;
    const info = storage.getInfo(bindingContext);
    const items = info.fieldSettings.items ?? [];
    const selected = items.find(item => item.id === value);
    const AccountAssignment = (selected?.additionalData ?? {}) as IDocumentAccountAssignmentEntity;
    const accounts: (keyof IDocumentAccountAssignmentEntity)[] = ["CreditAccount", "DebitAccount"];
    for (const account of accounts) {
        const Account = AccountAssignment[account] as IAccountEntity;
        if (Account?.IsClosed) {
            const num = Account.Number;
            return new ValidationError(i18next.t("Document:AccountAssignment.AccountClosed", { num }), false, args.bindingContext.getNavigationPath(false));
        }
    }
    return true;
};

export const accountsAreSetValidator: TValidatorFn = (value, args) => {
    const { storage, bindingContext } = args;
    const accounts: (keyof IDocumentAccountAssignmentEntity)[] = ["CreditAccount", "DebitAccount"];
    const codeBc = bindingContext.getParent().navigate("SelectionCode");
    const code = storage.getValue(codeBc) as SelectionCode;
    if (![SelectionCode.Own, SelectionCode.Copy].includes(code)) {
        return true;
    }
    for (const account of accounts) {
        const bc = bindingContext.navigate(account);
        if (!storage.getValue(bc, { useDirectValue: false })) {
            return new ValidationError(i18next.t("Document:AccountAssignment.SubfieldsNotValid"), false, args.bindingContext.getNavigationPath(false));
        }
    }
    return true;
};

export const getAccountAssignmentItemDef = (collectionName: string, documentType: DocumentTypeCode, addDefault: boolean, hasAccountAssignment: boolean): TFieldsDefinition => {
    const accountAssignmentPath = `${collectionName}/AccountAssignmentSelection/AccountAssignment`;
    return {
        [accountAssignmentPath]: {
            isRequired: true,
            fieldSettings: {
                displayName: "ShortName",
                additionalItems: getAdditionalLineAccAssItems(addDefault),
                entitySet: EntitySetName.ChartedAccountAssignments
            },
            customizationData: {
                useForCustomization: ifAll(isNotTaxEvidence, hasAccountAssignment)
            },
            isVisible: isNotTaxEvidence,
            columns: accountAssignmentSelectColumns,
            width: FastEntryInputSizes.M,
            type: FieldType.ComboBox,
            cacheStrategy: CacheStrategy.View,
            filter: {
                select: accAssignmentFilterWithType.bind(accAssignmentFilterWithType, documentType)
            },
            comparisonFunction: accountAssignmentComparisonFunction,
            formatter: accAssignmentItemFormatter,
            defaultValue: SelectionCode.Default,
            validator: {
                type: ValidatorType.Custom,
                settings: {
                    customValidator: [
                        {
                            validator: defaultAccountAssignmentValidator,
                            message: i18next.t("Document:AccountAssignment.DefaultNotAllowedWhenEmpty")
                        },
                        {
                            validator: accountsAreClosedValidator
                        },
                        {
                            validator: accountsAreSetValidator
                        }
                    ]
                }
            },
            additionalProperties: [
                // ChartOfAccounts needs to be present so that we can infer correct entity set during saving
                { id: "ChartOfAccounts" },
                { id: "DebitAccount" },
                { id: "CreditAccount" },
                { id: ItemCreateCustomAssignmentBc },
                { id: `/${collectionName}/AccountAssignmentSelection/Selection` },
                { id: "Name" }
            ]
        },
        [`${accountAssignmentPath}/${ItemCreateCustomAssignmentBc}`]: {
            type: FieldType.Checkbox,
            label: i18next.t("Document:AccountAssignment.SaveAsNew"),
            labelStatus: LabelStatus.Hidden,
            defaultValue: false,
            useForValidation: false,
            customizationData: {
                useForCustomization: false
            },
            isDisabled: args => {
                return !args.storage.context.getCompanyPermissions().has(CompanyPermissionCode.DocumentPosting);
            },
            isVisible: userHasTemplateManagementPermissions
        },
        [`${accountAssignmentPath}/DebitAccount`]: {
            label: i18next.t("Document:Form.DebitAccount"),
            ...generalAccountProps(`/${collectionName}/AccountAssignmentSelection/AccountAssignment/DebitAccount/Name`),
            width: BasicInputSizes.L,
            isRequired: hasOwnSelection,
            useForValidation: true,
            customizationData: {
                useForCustomization: false
            }
        },
        [`${accountAssignmentPath}/CreditAccount`]: {
            label: i18next.t("Document:Form.CreditAccount"),
            ...generalAccountProps(`/${collectionName}/AccountAssignmentSelection/AccountAssignment/CreditAccount/Name`),
            width: BasicInputSizes.L,
            isRequired: hasOwnSelection,
            useForValidation: true,
            customizationData: {
                useForCustomization: false
            }
        },
        [`${accountAssignmentPath}/Name`]: {
            width: BasicInputSizes.L,
            isRequired: hasOwnSelection,
            useForValidation: true,
            customizationData: {
                useForCustomization: false
            }
        }
    };
};

const accountFormatter = (val: TValue, args?: IFormatOptions) => {
    if (!val) {
        return "";
    }
    const account = args.storage.getValue(args.bindingContext) as IAccountEntity;
    return !!account.Number && !!account.Name ? `${account.Number} - ${account.Name}` : "";
};

export const getAccountSelectionFieldsDef = (documentType: DocumentTypeCode, args: IGetAccAssignmentGroupArgs = {}): Record<string, IFieldInfoProperties> => {
    const collapsedRows = [
        [
            { id: getFieldId("DebitAccount", args.isWithoutSelectionPrefix) },
            { id: getFieldId("CreditAccount", args.isWithoutSelectionPrefix) }
        ],
        [
            { id: getFieldId("Name", args.isWithoutSelectionPrefix) }
        ]
    ];

    if (!args.skipCreateCustom) {
        collapsedRows[1].push({ id: CreateCustomAssignmentPath });
    }

    const obj: Record<string, IFieldInfoProperties> = {
        [getFieldId("", args.isWithoutSelectionPrefix)]: {
            type: FieldType.ComboBox,
            cacheStrategy: CacheStrategy.View,
            label: args.isLabelWithoutDefaultPrefix ? i18next.t("Document:AccountAssignment.AccountAssignment") : i18next.t("Document:Form.DefaultVat"),
            fieldSettings: {
                entitySet: EntitySetName.ChartedAccountAssignments,
                displayName: "ShortName",
                noRecordText: args.skipNoRecordText ? null : i18next.t("Common:Select.NoRecord"),
                additionalItems: getAdditionalAccAssItems(args.skipNoneItem)
            },
            columns: accountAssignmentSelectColumns,
            width: BasicInputSizes.XL,
            additionalProperties: [
                { id: "Name" },
                // ChartOfAccounts needs to be present so that we can infer correct entity set during saving
                { id: "ChartOfAccounts" }
            ],
            collapsedRows,
            filter: {
                select: accAssignmentFilterWithType.bind(accAssignmentFilterWithType, documentType)
            },
            validator: {
                type: ValidatorType.Custom,
                settings: {
                    customValidator: accountsAreClosedValidator
                }
            },
            affectedFields: [
                { id: "AccountAssignmentSelection/AccountAssignment/DebitAccount", revalidate: true },
                { id: "AccountAssignmentSelection/AccountAssignment/CreditAccount", revalidate: true },
                { id: "Items/AccountAssignmentSelection/AccountAssignment", revalidate: true }
            ],
            formatter: accAssignmentFormatter,
            comparisonFunction: accountAssignmentComparisonFunction,
            customizationData: {
                useForCustomization: isNotTaxEvidence
            }
        },
        [getFieldId("DebitAccount", args.isWithoutSelectionPrefix)]: {
            ...generalAccountProps(`/${getFieldId("DebitAccount/Name", args.isWithoutSelectionPrefix)}`, args.transactionDatePath),
            width: BasicInputSizes.L,
            formatter: accountFormatter,
            isRequired: isVatChildRequired,
            isVisible: isVatFieldVisible
        },
        [getFieldId("CreditAccount", args.isWithoutSelectionPrefix)]: {
            ...generalAccountProps(`/${getFieldId("CreditAccount/Name", args.isWithoutSelectionPrefix)}`, args.transactionDatePath),
            width: BasicInputSizes.L,
            formatter: accountFormatter,
            isRequired: isVatChildRequired,
            isVisible: isVatFieldVisible
        },
        [getFieldId("Name", args.isWithoutSelectionPrefix)]: {
            customizationData: {
                useForCustomization: isNotTaxEvidence
            },
            width: BasicInputSizes.XL,
            isRequired: isVatChildRequired,
            isVisible: isVatFieldVisible
        },
        [CreateCustomAssignmentPath]: {
            type: FieldType.Checkbox,
            label: i18next.t("Document:AccountAssignment.SaveAsNew"),
            labelStatus: LabelStatus.Hidden,
            defaultValue: false,
            isVisible: ifAll(userHasTemplateManagementPermissions, isOwnAccountAssignmentSelection),
            isDisabled: args => {
                return !args.storage.context.getCompanyPermissions().has(CompanyPermissionCode.DocumentPosting);
            },
            customizationData: {
                useForCustomization: isNotTaxEvidence
            }
        }
    };

    return obj;
};

export interface IGetAccAssignmentGroupArgs {
    skipCreateCustom?: boolean;
    skipNoneItem?: boolean;
    skipNoRecordText?: boolean;
    hideTitle?: boolean;
    /** Don't use AccountAssignmentSelection in paths */
    isWithoutSelectionPrefix?: boolean;
    isLabelWithoutDefaultPrefix?: boolean;
    /** Path to date that will be used by getCurrentCoAId to find correct CoA when loading accounts */
    transactionDatePath?: string;
}

const isNotTaxEvidence = (args: IGetValueArgs): boolean => {
    return !isCashBasisAccountingCompany(args.context ?? args.storage.context);
};

export const getAccAssignmentGroup = ({
                                          hideTitle,
                                          isWithoutSelectionPrefix
                                      }: IGetAccAssignmentGroupArgs = {}): IFormGroupDef => {
    return {
        id: ACCOUNT_ASSIGNMENT_GROUP_ID,
        isVisible: isNotTaxEvidence,
        customizationData: {
            useForCustomization: isNotTaxEvidence
        },
        rows: [[{ id: getFieldId("", isWithoutSelectionPrefix) }]],
        title: hideTitle ? "" : i18next.t("Document:FormGroup.WithoutVat")
    };
};

/**
 * All VATs, which was valid through the time for current agenda's country
 * @param args
 */
export const currentAgendaVatsFilter = (args?: IGetValueArgs): string => {
    return `CountryCode eq '${getAgendaCountryCode(args.storage.context)}'`;
};

const hasAccountAssignment = (storage: FormStorage, collectionName = "Items") => storage.data.definition.fieldDefinition["AccountAssignmentSelection/AccountAssignment"] || storage.data.definition.fieldDefinition[`${collectionName}/AccountAssignmentSelection/AccountAssignment`];

// TODO what is the difference between this and correctAccountId? shouldn't it be just one function?
export const processAccountAssignmentSelection = (data: TRecordAny, isLineItem: boolean, isNew: boolean): void => {
    const selectionCode = data?.AccountAssignmentSelection?.Selection?.Code;
    const AApath = "AccountAssignmentSelection/AccountAssignment";
    if (selectionCode) {
        if (selectionCode === SelectionCode.None || selectionCode === SelectionCode.Default) {
            setNestedValue(selectionCode, `${AApath}/Id`, data);
            setNestedValue(undefined, `${AApath}/Name`, data);
        }
        if (selectionCode === SelectionCode.Own || selectionCode === SelectionCode.Split) {
            setNestedValue(selectionCode, `${AApath}/Id`, data);
        }
    } else if (isNew && isLineItem) {
        setNestedValue(SelectionCode.Default, `${AApath}/Id`, data);
        setNestedValue(SelectionCode.Default, "AccountAssignmentSelection/Selection/Code", data);
    }
};

export const setCorrectSpecialItemsForAccountAssignment = (storage: FormStorage, collectionName = "Items"): void => {
    if (!hasAccountAssignment(storage, collectionName)) {
        return;
    }
    const isNew = storage.data.bindingContext.isNew();
    const hasRootAa = storage.isValidPath("AccountAssignmentSelection/AccountAssignment");
    if (hasRootAa) {
        processAccountAssignmentSelection(storage.data.entity, false, isNew);
    }

    for (const item of (storage.data.entity[collectionName] || [])) {
        processAccountAssignmentSelection(item, true, isNew);
    }

    // close group if selection is None
    if (hasRootAa) {
        const assignment = storage.getValueByPath("AccountAssignmentSelection/AccountAssignment/Id");
        if (isNotDefined(assignment) || assignment === SelectionCode.None) {
            storage.expandGroup(false, ACCOUNT_ASSIGNMENT_COLLAPSED_GROUP_ID);
        }
    }
};

export interface IPrepareAcAssForSaveOptions {
    collectionName?: string;
    transactionDate?: Date;
}

export const prepareAccountAssignmentsForSave = (storage: FormStorage, data: IEntity, options: IPrepareAcAssForSaveOptions = {}): void => {
    if (!hasAccountAssignment(storage, options?.collectionName)) {
        return;
    }

    const selection = data.AccountAssignmentSelection?.Selection?.Code;
    if (!selection) {
        data.AccountAssignmentSelection = null;
    } else if (selection === SelectionCode.None) {
        data.AccountAssignmentSelection.AccountAssignment = {};
    } else if (selection === SelectionCode.Default) {
        data.AccountAssignmentSelection.AccountAssignment = {};
    } else if (selection === SelectionCode.Own) {

        const origAccAssShortName = getNestedValue("AccountAssignmentSelection/AccountAssignment/ShortName", data)?.split("/");
        const daNumber = getNestedValue("AccountAssignmentSelection/AccountAssignment/DebitAccount/Number", data) ?? origAccAssShortName?.[0];
        const caNumber = getNestedValue("AccountAssignmentSelection/AccountAssignment/CreditAccount/Number", data) ?? origAccAssShortName?.[1];
        const shortName = (caNumber && daNumber) ? `${daNumber}/${caNumber}` : origAccAssShortName;

        setNestedValue(shortName, "AccountAssignmentSelection/AccountAssignment/ShortName", data);
    }

    if (selection === SelectionCode.Split) {
        const coaId = getCurrentCoAId({ storage: storage }, { transactionDate: options.transactionDate });
        for (const item of data.SplitAccountAssignments || []) {
            item.ChartOfAccounts = {
                Id: coaId
            };

            const exRate = storage.data.entity.ExchangeRatePerUnit ?? 1;
            item.Amount = roundToDecimalPlaces(2, item.TransactionAmount * exRate);
        }

        if (data.AccountAssignmentSelection.AccountAssignment) {
            data.AccountAssignmentSelection.AccountAssignment = null;
        }

        return;
    } else if (data.SplitAccountAssignments) {
        data.SplitAccountAssignments = [];
    }

    // change AccountAssignmentSelection.Own to AccountAssignmentSelection.Copy if CreateCustomAssignment is checked
    // check both CreateCustomAssignment and ItemCreateCustomAssignment so that it works for both document and document item
    const ownAccountAssignment = data.AccountAssignmentSelection?.Selection?.Code === SelectionCode.Own;
    const createAssignment = data[CreateCustomAssignmentPath] ?? data.AccountAssignmentSelection?.AccountAssignment?.[ItemCreateCustomAssignmentBc];

    if (data?.AccountAssignmentSelection) {
        if (ownAccountAssignment && createAssignment) {
            data.AccountAssignmentSelection.Selection.Code = SelectionCode.Copy;
        }

        // remove NotId property that came from ChartedAccountAssignments
        if (data.AccountAssignmentSelection.AccountAssignment) {
            delete data.AccountAssignmentSelection.AccountAssignment.NotId;

            if ([SelectionCode.Copy, SelectionCode.Own].includes(data.AccountAssignmentSelection.Selection.Code as SelectionCode)) {
                // use fallbackToActiveChOA, we need CoA always present in case drafts are being saved when wrong DateAccountingTransaction is selected,
                // otherwise ChartsOfAccounts(null)/Accounts(xyz) is send on BE and it throws ugly error
                const coaId = getCurrentCoAId({ storage: storage }, {
                    transactionDate: options.transactionDate,
                    fallbackToActiveChOA: true
                });
                setNestedValue(coaId, "AccountAssignmentSelection/AccountAssignment/ChartOfAccounts/Id", data);
            }
        }
    }
};

const changeAccountAssignmentSelection = (storage: FormStorage, value: string) => {
    storage.setValueByPath("AccountAssignmentSelection/Selection/Code", value);
};

export const handleAccAssignmentChildrenChange = (storage: FormStorage, e: ISmartFieldChange): void => {
    let assignmentBc: BindingContext;

    // Credit or Debit account change on both - main account assigment or item
    const isAccountBc = ["CreditAccount", "DebitAccount"].includes(e.bindingContext.getPath());
    const isMainDocumentAssignment = e.bindingContext.getNavigationPath(true).startsWith("AccountAssignmentSelection/AccountAssignment/");
    if (isAccountBc) {
        storage.setValue(e.bindingContext.navigate("Number"), e.additionalData?.Number);
        storage.setValue(e.bindingContext.navigate("Name"), e.additionalData?.Name);

        assignmentBc = e.bindingContext.getParent();
        const debitNumber = storage.getValue(assignmentBc.navigate("DebitAccount/Number"));
        const creditNumber = storage.getValue(assignmentBc.navigate("CreditAccount/Number"));
        if (debitNumber || creditNumber) {
            const fallback = DASH_CHARACTER;
            storage.setValue(assignmentBc.navigate("ShortName"), `${debitNumber ?? fallback}/${creditNumber ?? fallback}`);
        }
    }

    // Only main account assignment
    if (isMainDocumentAssignment) {
        assignmentBc = storage.data.bindingContext.navigate("AccountAssignmentSelection/AccountAssignment");

        const isAnyAccountSet = ["CreditAccount", "DebitAccount"].find(path => {
            const bc = assignmentBc.navigate(path);
            return !!storage.getValue(bc, { useDirectValue: false });
        });
        if (e.bindingContext.getPath() === "Name" && !e.value && !isAnyAccountSet) {
            storage.clearValue(assignmentBc);
            changeAccountAssignmentSelection(storage, undefined);
        } else {
            storage.setValue(assignmentBc, SelectionCode.Own);
            changeAccountAssignmentSelection(storage, SelectionCode.Own);
        }

    }

    if (assignmentBc && storage.getInfo(assignmentBc)) {
        storage.addActiveGroup(assignmentBc);
        storage.addActiveGroupByKey("Items");
    }
};

export async function correctAccountIdAfterChange(storage: FormStorage, e: ISmartFieldChange): Promise<void> {
    const path = e.bindingContext.getPath();
    const assignmentBc = e.bindingContext.getParent();
    const assignmentPath = assignmentBc.getPath();

    if (assignmentPath === "AccountAssignment" && (e.triggerAdditionalTasks || ["Name"].includes(path))) {
        const info = storage.getInfo(assignmentBc);
        if (info) {
            // correctAccountId directly changes the selection object, so we need the correct one (not from temporal data)
            const selection = storage.getValue(assignmentBc.getParent(), { skipTemporaryValue: true }) as IDocumentAccountAssignmentSelectionEntity;
            const currentId = storage.getValue(assignmentBc, { useDirectValue: false });
            if (currentId) {
                if (!selection.AccountAssignment) {
                    selection.AccountAssignment = {};
                }
                // fill with possible temporary value from Items dialog
                selection.AccountAssignment.Id = currentId;

                // AccountAssignment can be fetching new items at this very moment
                // => wait for the result before using the items value
                const items = await fetchItemsByInfo(storage, info);

                correctAccountId(storage, selection, items, assignmentBc);
            }
            storage.validateFieldSync(assignmentBc);
            storage.addActiveGroup(assignmentBc);
        }
    }
}

interface IAccAssHandlerCustomData extends IFormStorageDefaultCustomData {
    updatingAssignment?: IAccountAssignmentEntity;
}

export interface IHandleAccAssChangeArgs {
    storage: FormStorage<unknown, IAccAssHandlerCustomData>;
    event: ISmartFieldChange;
    documentTypeCode: DocumentTypeCode;
}

export const handleAccAssignmentChange = async (args: IHandleAccAssChangeArgs): Promise<boolean> => {
    const path = args.event.bindingContext.getNavigationPath(true);
    if ((path === CreateCustomAssignmentPath || path?.includes(ItemCreateCustomAssignmentBc)) && args.event.value) {
        // we have to check whether this assignment already exists
        // if so we don't create new one, but update old one.
        // for this purpose we display warning alert to notify the user
        // also we store ID and document types so we can add current document type if it is missing in updated assignment

        const accountAssignment = args.storage.getValue(args.event.bindingContext.getParent())?.AccountAssignmentSelection?.AccountAssignment;
        const ca = accountAssignment?.CreditAccount?.Number;
        const da = accountAssignment?.DebitAccount?.Number;
        const name = accountAssignment?.Name;
        const filter = `CreditAccountNumber eq '${ca}' AND DebitAccountNumber eq '${da}' AND Name eq '${name}'`;
        const query = args.storage.oData.getEntitySetWrapper(EntitySetName.AccountAssignments).query().filter(filter).expand("DocumentTypes");
        const assignments = await query.fetchData<IAccountAssignmentEntity[]>();
        const existingAssignment = assignments?.value[0];
        args.storage.setCustomData({ updatingAssignment: existingAssignment });

        if (existingAssignment) {
            const isInDocTypes = !!(existingAssignment?.DocumentTypes?.find((docType: TRecordAny) => docType.DocumentTypeCode === args.documentTypeCode));
            if (isInDocTypes) {
                // existing account change select to this account
                // same stuff just switch to existing assignment
                const bc = args.event.bindingContext.getParent().navigate("AccountAssignmentSelection/AccountAssignment");
                // storage.clearAndSetValue(bc, existingAssignment.Id);
                const items = args.storage.getInfo(bc)?.fieldSettings?.items;
                const matchingItem = findMatchingPureAccAss(existingAssignment, items);
                if (matchingItem) {
                    args.storage.clearAndSetValue(bc, matchingItem.id);
                    const selBc = args.event.bindingContext.getParent().navigate("AccountAssignmentSelection/Selection");
                    args.storage.clearAndSetValue(selBc, SelectionCode.Copy);
                    args.storage.refreshGroup(bc);
                }
            }
        }
    }

    if (path.startsWith("AccountAssignmentSelection/AccountAssignment")) {
        args.storage.setValueByPath(CreateCustomAssignmentPath, false);
    }

    if (path === "AccountAssignmentSelection/AccountAssignment" && args.event.triggerAdditionalTasks) {

        const shouldLoadFields = _handleAccAssignmentChange(args.storage, args.event.bindingContext, args.event.value);

        if (shouldLoadFields) {
            args.storage.processDependentField(getAccountAssignmentDependentFields(false), args.event.additionalData, args.event.bindingContext);
        }
    }

    return true;
};

/**
 * Common method used to transform selected AccountAssignment -> DocumentAccountAssignment
 *
 * @param storage
 * @param bindingContext
 * @param id
 */
export const _handleAccAssignmentChange = (storage: FormStorage, bindingContext: BindingContext, id: TValue): boolean => {
    let shouldProcessLocalDependentFields = true;
    if (!hasAccountAssignment(storage)) {
        return shouldProcessLocalDependentFields;
    }

    const _clearAccounts = () => {
        for (const path of vatAssignmentFields) {
            const bc = storage.data.bindingContext.navigate(path);
            storage.clearAndSetValue(bc, {});
        }
        storage.setValueByPath("AccountAssignmentSelection/AccountAssignment/Name", "");
    };

    if (!id || id === SelectionCode.None) {
        changeAccountAssignmentSelection(storage, id ? SelectionCode.None : undefined);
        _clearAccounts();
        // close the group if selection is "None"
        storage.expandGroup(false, ACCOUNT_ASSIGNMENT_COLLAPSED_GROUP_ID);
        storage.refreshGroup(bindingContext);
        storage.refreshGroupByKey("Items");
    } else if (id === SelectionCode.Own) {
        const oldValue = storage.getValue(bindingContext, { useDirectValue: false });
        if (!oldValue || oldValue !== SelectionCode.Own) {
            storage.expandGroup(true, ACCOUNT_ASSIGNMENT_COLLAPSED_GROUP_ID);
            changeAccountAssignmentSelection(storage, SelectionCode.Own);
            storage.addActiveGroupByKey(ACCOUNT_ASSIGNMENT_GROUP_ID);
        } else {
            shouldProcessLocalDependentFields = false;
        }
    } else if (id) {
        changeAccountAssignmentSelection(storage, SelectionCode.Copy);
        // data copying is handled by localDependentFields
        storage.refreshGroup(bindingContext);
    }

    return shouldProcessLocalDependentFields;
};

/** Apparently, some accounts shouldn't be used in AccountAssignments. It isn't BE error, but we should not allow it.
 * @see https://solitea-cz.atlassian.net/browse/DEV-17670
 * If analytical account (either for banks or cashboxes) is used, we should swap it with its root parent (BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX/CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX). */
const fixNewAccountAssigment = async (storage: FormStorage, accountAssignment: IAccountAssignmentEntity): Promise<IAccountAssignmentEntity> => {
    const cleanAccountAssignment = { ...accountAssignment };
    const prefixes = [BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX.toString(), CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX.toString()];
    let accountItems: ISelectItem[];
    let wasChanged = false;

    const fnGetAccountItems = async () => {
        if (!accountItems) {
            // we need to  fetch accounts, to find name of the right root account
            const accountPath = "Items/AccountAssignmentSelection/AccountAssignment/CreditAccount";
            accountItems = await fetchItemsByInfo(storage, storage.getInfo(storage.data.bindingContext.navigate(accountPath)));
        }

        return accountItems;
    };

    for (const prefix of prefixes) {
        if (cleanAccountAssignment.CreditAccountNumber.startsWith(prefix) && cleanAccountAssignment.CreditAccountNumber !== prefix) {
            const accountItems = await fnGetAccountItems();
            const rootAccount = accountItems.find((item) => (item.additionalData as IAccountEntity).Number === prefix)?.additionalData as IAccountEntity;

            cleanAccountAssignment.CreditAccountNumber = prefix;
            cleanAccountAssignment.CreditAccountName = rootAccount.Name;
            wasChanged = true;
        }

        if (cleanAccountAssignment.DebitAccountNumber.startsWith(prefix) && cleanAccountAssignment.DebitAccountNumber !== prefix) {
            const accountItems = await fnGetAccountItems();
            const rootAccount = accountItems.find((item) => (item.additionalData as IAccountEntity).Number === prefix)?.additionalData as IAccountEntity;

            cleanAccountAssignment.DebitAccountNumber = prefix;
            cleanAccountAssignment.DebitAccountName = rootAccount.Name;
            wasChanged = true;
        }
    }

    if (wasChanged) {
        const origAccAssShortName = cleanAccountAssignment.ShortName;
        const shortName = (cleanAccountAssignment.DebitAccountNumber && cleanAccountAssignment.CreditAccountNumber) ? `${cleanAccountAssignment.DebitAccountNumber}/${cleanAccountAssignment.CreditAccountNumber}` : origAccAssShortName;

        cleanAccountAssignment.ShortName = shortName;
    }

    return cleanAccountAssignment;
};

export const sendAdditionalRequestForAccountAssignment = async (storage: FormStorage<unknown, IAccAssHandlerCustomData>, entity: IEntity, batch: BatchRequest, docTypeCode: DocumentTypeCode, collectionName = "Items"): Promise<boolean> => {
    let shouldReloadAccountAssignment = false;
    if (!hasAccountAssignment(storage, collectionName)) {
        return shouldReloadAccountAssignment;
    }
    const accountAssignmentsWrapper = batch.getEntitySetWrapper(EntitySetName.AccountAssignments);

    const _addBatch = async (entity: IEntity, key: string) => {
        const createAssignment = key === CreateCustomAssignmentPath ? entity[key] : entity.AccountAssignmentSelection?.AccountAssignment?.[key];
        if (createAssignment) {
            shouldReloadAccountAssignment = true;
            // DocumentAccountAssignments has references to debit/credit account, just like AccountAssignmentWithReferences
            // but we are creating new AccountAssignment which have CreditAccountName + CreditAccountNumber instead
            const documentAccountAssignment = entity.AccountAssignmentSelection.AccountAssignment as IDocumentAccountAssignmentEntity;
            const newAccountAssignment: IAccountAssignmentEntity = await fixNewAccountAssigment(storage, {
                CreditAccountName: documentAccountAssignment.CreditAccount?.Name,
                CreditAccountNumber: documentAccountAssignment.CreditAccount?.Number,
                DebitAccountName: documentAccountAssignment.DebitAccount?.Name,
                DebitAccountNumber: documentAccountAssignment.DebitAccount?.Number,
                Name: documentAccountAssignment.Name,
                ShortName: documentAccountAssignment.ShortName,
                Note: documentAccountAssignment.Note
            });

            const upAssignment = storage.getCustomData().updatingAssignment;

            if (upAssignment) {
                const type = upAssignment.DocumentTypes?.find((type: TRecordAny) => type.DocumentTypeCode === docTypeCode);
                if (!type) {
                    newAccountAssignment.DocumentTypes = [{ DocumentTypeCode: docTypeCode }];
                }
                accountAssignmentsWrapper.update(upAssignment.Id, newAccountAssignment);
            } else {
                newAccountAssignment.DocumentTypes = [{ DocumentTypeCode: docTypeCode }];
                accountAssignmentsWrapper.create(newAccountAssignment);
            }
        }
    };

    await _addBatch(entity, CreateCustomAssignmentPath);
    for (const item of entity[collectionName] || []) {
        await _addBatch(item, ItemCreateCustomAssignmentBc);
    }

    return shouldReloadAccountAssignment;
};

export const invalidateAccountAssignments = (storage: FormStorage, collectionName = "Items"): void => {
    if (!hasAccountAssignment(storage, collectionName)) {
        return;
    }

    const bcs = [];

    for (const item of storage.data.entity[collectionName] || []) {
        const itemBc = storage.data.bindingContext.navigate(collectionName).addKey(item).navigate("AccountAssignmentSelection/AccountAssignment");
        bcs.push(itemBc);
    }

    invalidateItems(bcs, storage);
};

export const correctAccountIds = async (storage: FormStorage, collectionName = "Items"): Promise<void> => {
    if (!hasAccountAssignment(storage, collectionName)) {
        return;
    }

    const hasRootAa = storage.isValidPath("AccountAssignmentSelection/AccountAssignment");

    // call fetchAndSetItemsByInfo for each item, they could use different filter
    // fetchItems is cached, so it won't be called multiple times unnecessarily

    if (hasRootAa) {
        const items = await fetchItemsByPath(storage, "AccountAssignmentSelection/AccountAssignment");
        const accSel = storage.data.entity.AccountAssignmentSelection;

        correctAccountId(storage, accSel, items, storage.data.bindingContext.navigate("AccountAssignmentSelection/AccountAssignment"));
    }

    const itemsBc = storage.data.bindingContext.navigate(collectionName);

    for (const item of (storage.data.entity[collectionName] || [])) {
        const bc = itemsBc.addKey(item).navigate("AccountAssignmentSelection/AccountAssignment");
        // todo: these are the same items for each collection item, so we could cache them?
        const itemItems = await fetchItemsByInfo(storage, storage.getInfo(bc));

        correctAccountId(storage, item.AccountAssignmentSelection, itemItems, bc);
    }
};

const correctAccountId = (storage: FormStorage, accSel: IEntity, items: ISelectItem[], accountAssignmentBc: BindingContext) => {
    const id = accSel?.AccountAssignment?.Id;

    if (!id || id === SelectionCode.Split || id === SelectionCode.None || id === SelectionCode.Default || id === SelectionCode.Copy) {
        return;
    }
    const matchingAccount = findMatchingAccAss(accSel?.AccountAssignment, items ?? []);
    if (!matchingAccount) {
        setNestedValue(SelectionCode.Own, "Selection/Code", accSel);
        setNestedValue(SelectionCode.Own, "AccountAssignment/Id", accSel);
    } else {
        setNestedValue(SelectionCode.Copy, "Selection/Code", accSel);
        setNestedValue(matchingAccount.id, "AccountAssignment/Id", accSel);

        // in case shortname was changed in the original AA
        if (matchingAccount.additionalData?.ShortName && matchingAccount.additionalData.ShortName !== accSel.AccountAssignment.ShortName) {
            accSel.AccountAssignment.ShortName = matchingAccount.additionalData.ShortName;
        }
    }
};

/** Matching account assignments have same debit and credit account */
interface IAccountCollection {
    cas: ISelectItem[];
    das: ISelectItem[];
}

interface IGetMatchingAccountsArgs {
    storage: FormStorage;
    accountAssignment: IEntity;
    collection?: string;
    accounts?: IAccountCollection;
}

export const getMatchingAccounts = async (args: IGetMatchingAccountsArgs) => {
    let cas = args.accounts?.cas;
    let das = args.accounts?.das;
    if (!args.accounts) {
        const prefix = args.collection ? `${args.collection}/` : "";
        const caInfo = getInfoByPath(args.storage, `${prefix}AccountAssignmentSelection/AccountAssignment/CreditAccount`);
        const daInfo = getInfoByPath(args.storage, `${prefix}AccountAssignmentSelection/AccountAssignment/DebitAccount`);

        // this function is called without binding context - it is not related to any particular line item
        // => don't try to reuse already existing items, nor set the fetched accounts to any field info
        // calling fetchItemsByInfo multiple times should be fine, it is cached
        cas = await fetchItemsByInfo(args.storage, caInfo);
        das = await fetchItemsByInfo(args.storage, daInfo);
    }

    const ca = cas.find(ca => isMatchingAccount(ca.additionalData, args.accountAssignment.CreditAccount));
    const da = das.find(da => isMatchingAccount(da.additionalData, args.accountAssignment.DebitAccount));

    if (ca || da) {
        return { ca, da };
    }

    return null;
};

export const findMatchingAccAss = (accountAssignment: IDocumentAccountAssignmentEntity, items: ISelectItem[]) => {
    if (!accountAssignment) {
        return null;
    }

    return items.find((item) => {
        return accountAssignment.CreditAccount && isMatchingAccount(accountAssignment.CreditAccount, item.additionalData.CreditAccount)
            && accountAssignment.DebitAccount && isMatchingAccount(accountAssignment.DebitAccount, item.additionalData.DebitAccount)
            && accountAssignment.Name === item.additionalData.Name;
    });
};

const findMatchingPureAccAss = (accountAssignment: IEntity, items: ISelectItem[]) => {
    if (!accountAssignment) {
        return null;
    }

    return items.find((item) => {
        return accountAssignment.CreditAccountName === item.additionalData.CreditAccount?.Name && accountAssignment.CreditAccountNumber === item.additionalData.CreditAccount?.Number
            && accountAssignment.DebitAccountName === item.additionalData.DebitAccount?.Name && accountAssignment.DebitAccountNumber === item.additionalData.DebitAccount?.Number
            && accountAssignment.Name === item.additionalData.Name;
    });
};

export const loadAccountAssignments = async (storage: FormStorage, collectionName?: string): Promise<ISelectItem[]> => {
    const prefix = collectionName ? `${collectionName}/` : "";
    return fetchItemsByPath(storage, `${prefix}AccountAssignmentSelection/AccountAssignment`);
};

export const reloadAccountsAndAssignments = async (storage: FormStorage, fiscalYear: IFiscalYearEntity, hasRootAssignment = true, collectionName = "Items", withoutBusy = false): Promise<void> => {
    if (!hasAccountAssignment(storage, collectionName)) {
        return;
    }
    if (!withoutBusy) {
        // we can't let user save the form before the values are changed => busy indicator
        storage.setBusy(true);
    }

    const accAssPath = "AccountAssignmentSelection/AccountAssignment";

    let rootBcs: BindingContext[] = [];
    let cacheStrategy = CacheStrategy.View;

    if (hasRootAssignment) {
        const accAssBc = storage.data.bindingContext.navigate(accAssPath);
        const accAssInfo = storage.getInfo(accAssBc);

        rootBcs = [accAssBc, accAssBc.navigate("DebitAccount"), accAssBc.navigate("CreditAccount")];
        cacheStrategy = accAssInfo.cacheStrategy ?? CacheStrategy.View;
    }

    const itemsAccAssBcs: BindingContext[] = [];

    if (collectionName && storage.data.entity[collectionName]) {
        const subItems = storage.data.bindingContext.iterateNavigation(collectionName, storage.data.entity[collectionName]);
        for (const element of subItems || []) {
            itemsAccAssBcs.push(element.bindingContext.navigate(accAssPath));
        }
    }

    // clear cache and items (for document and all document items)  so that fetchSelectItems/SmartSelect can load new items
    invalidateItems([
        ...rootBcs,
        ...itemsAccAssBcs.flatMap((bc) => [bc, bc.navigate("DebitAccount"), bc.navigate("CreditAccount")])
    ], storage, cacheStrategy);

    // fetch new acc ass items before letting SmartSelect fetch them automatically,
    // so that we can change account assignment selection based on those new items
    // let SmartSelect handle debit/credit accounts
    // calling fetchSelectItems will store items in the cache as well => won't cause redundant requests
    const items = fiscalYear ? (await loadAccountAssignments(storage)) : [];

    const fnResetItemsForNonextistingFiscalYear = (accAssBc: BindingContext) => {
        const accAssInfo = storage.getInfo(accAssBc);

        // set items directly into fieldSettings, instead of letting SmartSelect handle it by itself
        // to prevent pointless request when no fiscal year exists
        accAssInfo.fieldSettings.items = [];
        storage.getInfo(accAssBc.navigate("DebitAccount")).fieldSettings.items = [];
        storage.getInfo(accAssBc.navigate("CreditAccount")).fieldSettings.items = [];
    };

    // change items and value for all DOCUMENT ITEMS acc assignment field
    if (hasRootAssignment) {
        const accAssBc = storage.data.bindingContext.navigate(accAssPath);
        itemsAccAssBcs.unshift(accAssBc);
    }

    for (const itemAccAssBc of itemsAccAssBcs) {
        if (!fiscalYear) {
            fnResetItemsForNonextistingFiscalYear(itemAccAssBc);
            clearSelect(storage, itemAccAssBc);
            continue;
        }

        const itemBc = itemAccAssBc.getParent().getParent();

        await rewriteAccountAssignment({
            storage,
            collectionName: itemAccAssBc.toString().includes(collectionName) ? collectionName : null,
            choaId: fiscalYear.ChartOfAccounts?.Id,
            itemBc,
            accountAssignments: items
        });
    }

    if (!withoutBusy) {
        storage.setBusy(false);
    }
};

const clearSelect = (storage: FormStorage, bc: BindingContext) => {
    storage.clearAndSetValue(bc, {});
    storage.clearAndSetValue(bc.getParent(), {});

    const ca = bc.navigate("CreditAccount");
    storage.clearValue(ca);

    const da = bc.navigate("DebitAccount");
    storage.clearValue(da);
};

interface IAccountCollection {
    cas: ISelectItem[];
    das: ISelectItem[];
}

interface IRewriteAccountAssignment {
    storage: FormStorage;
    choaId: number;
    accounts?: IAccountCollection;
    itemBc: BindingContext;
    accountAssignments: ISelectItem[];
    collectionName?: string;
}

export const getExpenseGains = (storage: FormStorage, id: number): IPaymentDocumentItemEntity[] => {
    return storage.data.entity.Items?.filter((item: IPaymentDocumentItemEntity) => item?.LinkedDocument?.Id === id
        && (item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeGain || item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeLoss));
};

interface IDateChangeArgs {
    value: Date;
    storage: FormStorage;
    bindingContext: BindingContext;
}

export const handleItemDateChange = async (args: IDateChangeArgs) => {
    const storage = args.storage;

    const _refreshAccounts = async () => {
        const date = args.value as Date;
        const choaId = getChoAIdBasedOnDate(storage as FormStorage, date, false);

        const itemBc = args.bindingContext.getParent();
        const asBc = itemBc.navigate("AccountAssignmentSelection/AccountAssignment");
        const as = storage.getValue(asBc);
        // try to find previous CoA id to prevent unnecessary reload
        // AccountAssignment doesn't have to be selected, so try to also take it from the fetched account assignment items
        const oldCoAId = as?.ChartOfAccounts?.Id ?? storage.getInfo(asBc).fieldSettings.items?.[0]?.additionalData?.ChartOfAccounts?.Id;

        if (oldCoAId !== choaId) {
            const caBc = asBc.navigate("CreditAccount");
            const daBc = asBc.navigate("DebitAccount");

            const caInfo = storage.getInfo(caBc);
            const daInfo = storage.getInfo(daBc);
            const asInfo = storage.getInfo(asBc);

            invalidateItems([asBc, caBc, daBc], storage);

            let cas: ISelectItem[] = [], das: ISelectItem[] = [], accounts: ISelectItem[] = [];

            if (choaId) {
                const promises = [fetchAndSetItemsByInfo(storage, caInfo), fetchAndSetItemsByInfo(storage, daInfo), fetchAndSetItemsByInfo(storage, asInfo)];
                [cas, das, accounts] = await Promise.all(promises);
            }

            await rewriteAccountAssignment({
                storage,
                accounts: { cas, das },
                choaId: choaId,
                itemBc,
                accountAssignments: accounts
            });

            if (linkedId && cas && das) {
                const gains = getExpenseGains(storage, linkedId);
                for (const gain of gains || []) {
                    const id = gain.Id ?? `#id=${(gain as IEntity)[BindingContext.NEW_ENTITY_ID_PROP]}`;
                    const bc = storage.data.bindingContext.navigate(`Items(${id})`);
                    const caIBc = bc.navigate("AccountAssignmentSelection/AccountAssignment/CreditAccount");
                    const daIBc = bc.navigate("AccountAssignmentSelection/AccountAssignment/DebitAccount");

                    const caIInfo = storage.getInfo(caIBc);
                    const daIInfo = storage.getInfo(daIBc);

                    invalidateItems([caIBc, daIBc], storage);

                    const promises = [fetchAndSetItemsByInfo(storage, caIInfo), fetchAndSetItemsByInfo(storage, daIInfo)];
                    const [casG, dasG] = await Promise.all(promises);

                    if (casG && dasG) {
                        const as = storage.getValue(bc.navigate("AccountAssignmentSelection/AccountAssignment"));
                        if (!as.ChartOfAccounts) {
                            as.ChartOfAccounts = {};
                        }

                        as.ChartOfAccounts.Id = choaId;

                        const ca = casG.find(ca => isMatchingAccount(ca.additionalData, as.CreditAccount));
                        const da = dasG.find(da => isMatchingAccount(da.additionalData, as.DebitAccount));

                        ca?.additionalData ? as.CreditAccount = { ...ca?.additionalData } : delete as.CreditAccount;
                        da?.additionalData ? as.DebitAccount = { ...da?.additionalData } : delete as.DebitAccount;
                    }
                }
            }
        }
    };

    const item = storage.getValue(args.bindingContext.getParent());
    const linkedId = item?.LinkedDocument?.Id;

    // gain date MUST be set BEFORE accounts are refreshed as their entity set depends on the date
    // THIS MUST BE SET BEFORE _refreshAccounts is called !!
    // so the corrent entityset callback is called
    if (linkedId) {
        const gains = getExpenseGains(storage, linkedId);
        for (const gain of gains || []) {
            const id = gain.Id ?? `#id=${gain[BindingContext.NEW_ENTITY_ID_PROP as keyof IPaymentDocumentItemEntity]}`;
            const _path = `Items(${id})/DateAccountingTransaction`;
            const bc = storage.data.bindingContext.navigate(_path);
            storage.setValue(bc, args.value);
        }
    }

    await _refreshAccounts();
};

export const rewriteSplitAccountAssignments = (args: IRewriteAccountAssignment) => {
    const item = args.storage.getValue(args.itemBc);
    const { storage } = args;

    const bcs = [];
    for (const element of args.itemBc.iterateNavigation(PaymentDocumentItemEntity.SplitAccountAssignments, item[PaymentDocumentItemEntity.SplitAccountAssignments])) {
        const caBc = element.bindingContext.navigate("CreditAccount");
        const daBc = element.bindingContext.navigate("DebitAccount");

        bcs.push(caBc);
        bcs.push(daBc);

        const ca = args.accounts.cas.find(ca => isMatchingAccount(ca.additionalData, element.entity.CreditAccount));
        const da = args.accounts.das.find(da => isMatchingAccount(da.additionalData, element.entity.DebitAccount));

        element.entity.CreditAccount = { ...ca?.additionalData };
        element.entity.DebitAccount = { ...da?.additionalData };

        if (!ca || !da) {
            storage.setError(args.itemBc.navigate("AccountAssignmentSelection/AccountAssignment"), {
                message: storage.t("Banks:Transactions.SetSplit"),
                errorType: ValidationErrorType.Field
            });
        }
    }

    invalidateItems(bcs, storage);
};

export const rewriteAccountAssignment = async (args: IRewriteAccountAssignment) => {
    const item = args.storage.getValue(args.itemBc);
    const isSplit = item?.AccountAssignmentSelection?.Selection?.Code === SelectionCode.Split;

    if (isSplit) {
        rewriteSplitAccountAssignments(args);
        return;
    }

    const aaBc = args.itemBc.navigate("AccountAssignmentSelection/AccountAssignment");
    const as = args.storage.getValue(aaBc);

    const fnApplyMatchingValues = (accAss: IEntity, matchingAccAss: IChartedAccountAssignmentEntity) => {
        accAss.Id = matchingAccAss.NotId;
        accAss.ChartOfAccounts = { ...matchingAccAss.ChartOfAccounts };
        accAss.CreditAccount = { ...matchingAccAss.CreditAccount };
        accAss.DebitAccount = { ...matchingAccAss.DebitAccount };
    };

    if (as && as.Id !== SelectionCode.Default && as.Id !== SelectionCode.None) {
        let matchingAccAss: IChartedAccountAssignmentEntity;

        // first, try to find matching account assignment
        // if it doesn't exist, or SelectionCode.Own, try to find matching accounts
        // if those don't exist, clear the select
        if (as.Id !== SelectionCode.Own) {
            matchingAccAss = findMatchingAccAss(as, args.accountAssignments)?.additionalData;

            if (matchingAccAss) {
                fnApplyMatchingValues(as, matchingAccAss);
            }
        }

        if (as.Id === SelectionCode.Own || !matchingAccAss) {
            // check whether there is credit and debit accounts existing in new fiscal period
            // if so, change the ChartOfAccounts based on that period
            const accounts = await getMatchingAccounts({
                storage: args.storage,
                accounts: args.accounts,
                accountAssignment: as,
                collection: args.collectionName
            });

            if (accounts && accounts.ca?.additionalData && accounts.da?.additionalData) {
                as.CreditAccount = { ...accounts.ca?.additionalData };
                as.DebitAccount = { ...accounts.da?.additionalData };
                as.ChartOfAccounts = {
                    ...as.ChartOfAccounts,
                    Id: args.choaId
                };
                as.Id = SelectionCode.Own;
                // set selection to Own as well, it could be something else
                args.storage.setValue(args.itemBc.navigate("AccountAssignmentSelection/Selection"), SelectionCode.Own);
            } else {
                clearSelect(args.storage, aaBc);
            }
        }
    }
};

export const clearItemAccAssignment = (storage: FormStorage, bindingContext: BindingContext): void => {
    // we need to clear both value and current value
    const bcs = ["CreditAccount", "DebitAccount"].map(id => bindingContext.navigate(id));
    bcs.forEach(bc => storage.clearAndSetValue(bc, {}));
    // Credit and DebitAccount are confirmable fields
    // storage.confirmFields(bcs);
};


export interface IAccAssDialogCustomData extends IFormStorageDefaultCustomData {
    isSplitDialogOpened?: boolean;
    splitDialogError?: IValidationError;
    isDialogOpen?: boolean;
    AccountAssignmentDialogBc?: BindingContext;
}


interface IItemAccAssChangeArgs {
    storage: FormStorage<unknown, IAccAssDialogCustomData>;
    e: ISmartFieldChange;
    oldId?: SelectionCode;
}

export const handleItemAccAssignmentDialog = ({ e, storage, oldId }: IItemAccAssChangeArgs): boolean => {
    const { bindingContext, value } = e;

    if (bindingContext?.getPath() === "AccountAssignment") {
        if (value === SelectionCode.Split && e.triggerAdditionalTasks) {
            storage.setCustomData({
                isSplitDialogOpened: true,
                splitDialogError: storage.getError(bindingContext),
                AccountAssignmentDialogBc: bindingContext
            });

            storage.setTemporalData(bindingContext, { value: SelectionCode.Split });
            storage.refresh();
            // stops processing the change, just opens the dialog
            return true;
        }

        if (value === SelectionCode.Own && e.triggerAdditionalTasks) {
            const currentAssignment = oldId ?? storage.getValue(bindingContext, { useDirectValue: false });
            if (!currentAssignment || currentAssignment !== SelectionCode.Own) {
                // fill temporal data because of validation in the temporal dialog
                AccountDialogFields.forEach(field =>
                    storage.setTemporalData(bindingContext.navigate(field), {
                        value: undefined,
                        additionalData: {},
                        additionalFieldData: {}
                    }));
            }

            // set Own selection to temporal data
            storage.setTemporalData(bindingContext, { value: SelectionCode.Own });
            storage.setTemporalData(bindingContext.getParent().navigate("Selection/Code"), { value: SelectionCode.Own });

            storage.setCustomData({
                isDialogOpen: true,
                AccountAssignmentDialogBc: bindingContext
            });

            storage.refresh();
            // stops processing the change, just opens the dialog
            return true;
        }
    }
    return false;
};

export const handleItemAccAssignmentChange = (args: IItemAccAssChangeArgs): void => {
    const { e, storage } = args;
    const { bindingContext } = e;

    if (bindingContext?.getPath() === "AccountAssignment") {
        const id = storage.getValue(bindingContext.navigate("Id"), { useDirectValue: false });
        const itemBc = bindingContext.getParent().getParent();
        const item = storage.getValue(itemBc);

        if (!id || id === SelectionCode.None) {
            setNestedValue(id, "AccountAssignmentSelection/Selection/Code", item);

            // null/AccountAssignmentSelection.None => completely remove AccountAssignment from item
            clearItemAccAssignment(storage, bindingContext);
        } else if (id === SelectionCode.Default) {
            setNestedValue(id, "AccountAssignmentSelection/Selection/Code", item);
        } else if (id !== SelectionCode.Default && id !== SelectionCode.None) {
            const value = storage.getValue(bindingContext, { useDirectValue: false });

            if (value !== SelectionCode.Own) {
                setNestedValue(SelectionCode.Copy, "AccountAssignmentSelection/Selection/Code", item);
            }

            // data are already stored in the item - they are loaded in the select in additional data
        }
        const selectionBc = bindingContext.getParent().navigate("Selection");
        storage.correctEnumValue(selectionBc, getNestedValue("AccountAssignmentSelection/Selection/Code", item));

        // we need to rerender debit/credit fields
        // todo should we automatically add fields with same prefix as active? =>
        // => AccountAssignmentSelection/AccountAssignment would cause AccountAssignmentSelection/AccountAssignment/DebitAccount to rerender
        storage.processDependentField(getAccountAssignmentDependentFields(true), e.additionalData, bindingContext);
    }
};

export const setDefaultAccountAssignment = async (storage: FormStorage, entitySet: string, withoutBusy = false): Promise<void> => {
    if (!isAccountAssignmentCompany(storage.context)) {
        return;
    }
    const accAssPath = "AccountAssignmentSelection/AccountAssignment";
    const defaultAccAssAccounts = cloneDeep(storage.data.definition.fieldDefinition[accAssPath]?.defaultValue) as any;

    if (!storage.data.entity.AccountAssignmentSelection?.AccountAssignment) {
        // set empty object to trigger error validation when empty
        storage.data.entity.AccountAssignmentSelection = {
            AccountAssignment: {}
        };
    }

    const _handleChange = async (event: ISmartFieldChange) => {
        handleAccAssignmentChildrenChange(storage, event);
        await handleAccAssignmentChange({
            storage, event,
            documentTypeCode: storage.data.entity.DocumentTypeCode
        });
        storage.handleChange(event);

        await correctAccountIdAfterChange(storage, event);
    };

    if (!defaultAccAssAccounts || !storage.data.fieldsInfo[`${entitySet}/${accAssPath}`]) {
        return;
    }

    if (!withoutBusy) {
        storage.setBusy(true);
    }

    // load items manually before select will do it automatically,
    // so that we are able to set default value to Account Assignment
    const accAssItems = await loadAccountAssignments(storage);


    const accAssBc = storage.data.bindingContext.navigate(accAssPath);

    let defaultAccAss: ISelectItem;

    if (accAssItems?.length > 0) {
        defaultAccAss = cloneDeep(
            accAssItems.find(accItem => {
                return accItem.additionalData.DebitAccount.Number === defaultAccAssAccounts?.DebitAccount?.Number && accItem.additionalData.CreditAccount.Number === defaultAccAssAccounts?.CreditAccount?.Number;
            })
        );
    } else {
        // if there is no accountAssignment, set SelectionCode.Own to prevent select from being in a wrong state
        defaultAccAss = cloneDeep(storage.getInfo(accAssBc)?.fieldSettings?.additionalItems?.find(item => item.id === SelectionCode.Own));
    }

    // todo is there a better way how to set a default account assignment?
    if (defaultAccAss) {
        await _handleChange({
            value: defaultAccAss.id,
            bindingContext: accAssBc,
            triggerAdditionalTasks: true,
            additionalData: defaultAccAss.additionalData,
            groupId: defaultAccAss.groupId,
            currentValue: defaultAccAss.label
        });

        if (defaultAccAss.id === SelectionCode.Own) {
            // try to select accounts by Number
            const accounts: { DebitAccount: ISelectItem[], CreditAccount: ISelectItem[] } = {
                DebitAccount: await fetchItemsByPath(storage, `${accAssPath}/DebitAccount`),
                CreditAccount: null
            };

            if (storage.getInfo(storage.data.bindingContext.navigate(`${accAssPath}/DebitAccount`)).filter
                !== storage.getInfo(storage.data.bindingContext.navigate(`${accAssPath}/CreditAccount`)).filter) {
                // fetch both account types if they have different filter
                accounts.CreditAccount = await fetchItemsByPath(storage, `${accAssPath}/CreditAccount`);
            } else {
                accounts.CreditAccount = cloneDeep(accounts.DebitAccount);
            }

            for (const prop of ["DebitAccount", "CreditAccount"]) {
                const { Number, Name } = defaultAccAssAccounts[prop] ?? {};
                const accountSelectItem = Number && accounts[prop as ("DebitAccount" | "CreditAccount")].find(item => item.additionalData.Number === Number
                    && (!Name || item.additionalData.Name === Name));

                if (accountSelectItem) {
                    await _handleChange({
                        value: accountSelectItem.id,
                        bindingContext: accAssBc.navigate(prop),
                        triggerAdditionalTasks: true,
                        additionalData: accountSelectItem.additionalData,
                        groupId: accountSelectItem.groupId,
                        currentValue: accountSelectItem.label
                    });
                }
            }
            if (defaultAccAssAccounts?.Name) {
                await _handleChange({
                    value: defaultAccAssAccounts.Name,
                    bindingContext: accAssBc.navigate("Name")
                });
            }
        }

    } else {
        storage.clearValue(accAssBc.navigate("DebitAccount"));
        storage.clearValue(accAssBc.navigate("CreditAccount"));
    }

    storage.refresh(true);

    if (!withoutBusy) {
        storage.setBusy(false);
    }
};

export function getDocumentTypeLabel(type: IDocumentTypeEntity): string {
    let key: string;

    switch (type.Code) {
        case DocumentTypeCode.ProformaInvoiceReceived:
            key = "ReceivedDDOPP";
            break;
        case DocumentTypeCode.ProformaInvoiceIssued:
            key = "IssuedDDOPP";
            break;
    }

    return key ? i18next.t(`Common:DocumentTypeName.${key}`) : type.Name;
}

export type AccountAssignmentAccountType =
    DocumentAccountAssignmentEntity.CreditAccount
    | DocumentAccountAssignmentEntity.DebitAccount;
export const creditAndDebitAccount: AccountAssignmentAccountType[] = [DocumentAccountAssignmentEntity.DebitAccount, DocumentAccountAssignmentEntity.CreditAccount];

/**
 * returns filter query for documents which has any item using specified account number
 * @param accountType
 * @param accountNumber
 */
export function getDocumentFilterByUsedAccountNumber(accountType: AccountAssignmentAccountType, accountNumber: number | string): string {
    const accountNumberPath = `AccountAssignmentSelection/AccountAssignment/${accountType}/Number`;
    const _getCondition = (prefix?: string) =>
        `startswith(${prefix ? `${prefix}/` : ""}${accountNumberPath}, '${accountNumber}')`;

    return `((${_getCondition()} AND Items/any(aa: aa/AccountAssignmentSelection/SelectionCode eq '${SelectionCode.Default}'))
    OR Items/any(aa: ${_getCondition("aa")}))`;
}


export const convertAccountAssignmentIntoSelection = (accountAssignment: IAccountAssignmentEntity): IDocumentAccountAssignmentSelectionEntity => {
    return {
        SelectionCode: SelectionCode.Copy,
        Selection: {
            Name: SelectionCode.Copy
        },
        AccountAssignment: {
            Name: accountAssignment.Name,
            ShortName: accountAssignment.ShortName,
            DebitAccount: {
                Name: accountAssignment.DebitAccountName,
                Number: accountAssignment.DebitAccountNumber
            },
            CreditAccount: {
                Name: accountAssignment.CreditAccountName,
                Number: accountAssignment.CreditAccountNumber
            }
        }
    };
};

export const simpleAccountAssignmentFormatter: TFormatterFn = (val: TValue, args?: IFormatOptions): string => {
    const accountAssignment = args.item as IAccountAssignmentEntity;

    if (!accountAssignment?.ShortName) {
        return "";
    }

    return `${accountAssignment.ShortName} - ${accountAssignment.Name}`;
};

export const getSimpleAccountAssignmentFieldDef = (): TFieldDefinition => {
    return {
        width: BasicInputSizes.XL,
        type: FieldType.ComboBox,
        fieldSettings: {
            displayName: AccountAssignmentEntity.ShortName,
            entitySet: EntitySetName.AccountAssignments,
            noRecordText: i18next.t("Common:Select.NoRecord"),
            localDependentFields: [
                { from: { id: "ShortName" }, to: { id: "ShortName" }, navigateFrom: NavigationSource.Itself },
                { from: { id: "Name" }, to: { id: "Name" }, navigateFrom: NavigationSource.Itself }
            ]
        },
        columns: [
            { id: AccountAssignmentEntity.ShortName },
            { id: AccountAssignmentEntity.Name }
        ],
        additionalProperties: [
            { id: AccountAssignmentEntity.ShortName },
            { id: AccountAssignmentEntity.Name }
        ],
        formatter: simpleAccountAssignmentFormatter
    };
};

export async function refreshAccountIdsForActualFiscalYear(storage: FormStorage, fiscalYear: IFiscalYearEntity, withoutBusy = true): Promise<void[]> {
    const promises = [];
    if (isAccountAssignmentCompany(storage.context)) {
        promises.push(reloadAccountsAndAssignments(storage, fiscalYear, true, "Items", withoutBusy));

        const hasFiscalYear = !!fiscalYear?.Id;
        if (isReceived((storage.data.entity as IDocumentEntity).DocumentTypeCode as DocumentTypeCode)) {
            const nonTaxAccountPath = createPath(
                DocumentEntity.VatClassificationSelection,
                DocumentVatClassificationSelectionEntity.VatClassification,
                DocumentVatClassificationEntity.NonTaxAccount
            );
            promises.push(reloadAccounts(storage, hasFiscalYear, nonTaxAccountPath));
        }
    }

    return Promise.all(promises);
}

export async function applyAccountFromTemplate(storage: FormStorage, accountBc: BindingContext, data: Pick<IAccountEntity, "Number" | "Name">): Promise<void> {
    const info = storage.getInfo(accountBc);
    if (info) {
        const accounts = info.fieldSettings?.items ?? await fetchAndSetItemsByInfo(storage, info, true);
        const account = accounts
            .find(item => item.additionalData.Number === data.Number && item.additionalData.Name === data.Name);
        if (account) {
            storage.setValue(accountBc, account.id);
            storage.processDependentField(info.fieldSettings.localDependentFields, account.additionalData, accountBc);
            storage.validateFieldSync(accountBc);
        } else {
            storage.clearValue(accountBc);
            storage.validateFieldSync(accountBc);
        }
    }
}
