import { AlertPosition } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { ButtonGroup, IconButton } from "@components/button";
import { HotspotViewIds } from "@components/hotspots/HotspotViewIds";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { DashboardBody, WriteLineWrapper } from "@components/navigation/NavDashboard.styles";
import { Separator } from "@components/separator/Separator";
import { ODataError } from "@odata/Data.types";
import { ICompany } from "@odata/EntityTypes";
import { CompanyEntity, ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { CompanyStateCode, GeneralPermissionCode, ProductCode } from "@odata/GeneratedEnums";
import { isBatchResultOk } from "@odata/OData";
import { WithOData, withOData } from "@odata/withOData";
import { getCompanySelectorTitleKey, isDemoTenant } from "@utils/CompanyUtils";
import { isVisibleInContainer } from "@utils/dom.utils";
import { isDefined, isNotDefined } from "@utils/general";
import { logger } from "@utils/log";
import { anyPartStartsWithAccentsInsensitive } from "@utils/string";
import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
    AddIcon,
    ArchiveFilledIcon,
    ArchiveIcon,
    BinIcon,
    IProps as IIconProps,
    RefreshIcon
} from "../../components/icon";
import WriteLine from "../../components/inputs/writeLine/WriteLine";
import { ScrollBar } from "../../components/scrollBar/ScrollBar";
import { Toolbar } from "../../components/toolbar";
import { COMPANY_REMOVAL_CANCEL, COMPANY_REMOVAL_REQUESTED } from "../../constants";
import { AppContext, AppMode, ContextEvents, IAppContext } from "../../contexts/appContext/AppContext.types";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { Status, TextAlign } from "../../enums";
import { ROUTE_HOME, ROUTE_NEW_COMPANY } from "../../routes";
import TestIds from "../../testIds";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { getConfirmationActionText } from "../../views/table/TableView.render.utils";
import { IDefinition } from "../PageUtils";
import { ViewStyledCompanyDashboard } from "./CompanyDashboard.styles";
import { getDefinitions } from "./CompanyDef";
import CompanyTile from "./CompanyTile";
import OurOrganizationTile from "./OurOrganizationTile";

interface INavDashboardProps extends WithTranslation, RouteComponentProps, WithOData,
    WithBusyIndicator, WithAlert, WithPermissionContext {
    className?: string;
}

interface IState {
    filter: string;
    scrollToCompanyId?: number;
    removingCompanies: { [companyId: number]: boolean };
    archivingCompanies: { [companyId: number]: boolean };
    companiesRequestedForRemoval: number[];
}

interface IScrollData {
    from: number;
    to: number;
}

class CompanyDashboard extends PureComponent<INavDashboardProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    _scrollRef = React.createRef<HTMLDivElement>();
    _bodyRef = React.createRef<HTMLDivElement>();
    _def: IDefinition;
    _preventScroll: boolean;
    _companiesWaitingForRemovalPromise: Promise<Response>;

    state: IState = {
        filter: "",
        removingCompanies: null,
        archivingCompanies: null,
        companiesRequestedForRemoval: []
    };

    componentDidMount(): void {
        this.context.eventEmitter.on(ContextEvents.CompanyChanged, this.handleCompanyChange);

        if (this.props.tReady) {
            this.forceUpdate(() => {
                this.scrollToCompany(this.context.getCompanyId());
            });
            this.initDefinitions();
        }

        this.updateCompaniesWaitingForRemoval();
    }

    componentDidUpdate(prevProps: INavDashboardProps, prevState: IState) {
        if (this.state.scrollToCompanyId && !prevState.scrollToCompanyId) {
            this.scrollToCompany(this.state.scrollToCompanyId);
            this.setState({
                scrollToCompanyId: null
            });
        }

        if (!prevProps.tReady && this.props.tReady) {
            this.scrollToCompany(this.context.getCompanyId());
            this.initDefinitions();
        }
    }

    componentWillUnmount() {
        this.context.eventEmitter.off(ContextEvents.CompanyChanged, this.handleCompanyChange);
    }

    get canManageCompanies(): boolean {
        const context = this.context as IAppContext;
        return !isDemoTenant(context) && this.props.permissionContext.generalPermissions.has(GeneralPermissionCode.CompanyManagement);
    }

    // When user reach company limit, he can only delete company, not archive or create a new one
    get hasReachedCompanyLimit(): boolean {
        const context = this.context as IAppContext;
        const contextData = context.getData();
        const tenant = contextData.tenant;
        const companies = contextData.companies;

        return (tenant.ProductCode !== ProductCode.EvalaForCompanies || companies.length === 0);
    }

    initDefinitions = () => {
        this._def = getDefinitions(this.context);
    };

    updateCompaniesWaitingForRemoval = async () => {
        let companies;
        try {
            this._companiesWaitingForRemovalPromise = fetch(COMPANY_REMOVAL_REQUESTED, {
                ...getDefaultPostParams(),
                body: JSON.stringify([...this.companies.map(company => company.Id)])
            });

            const res = await this._companiesWaitingForRemovalPromise;

            companies = await res.json();

            this.setState({
                companiesRequestedForRemoval: companies
            });
        } catch (e) {
            // fall silently
            logger.error("Fail to update companies waiting for removal", e);
        }
    };

    getFilteredCompanies = () => {
        return this.context.getData().companies.filter((company: ICompanyEntity) => {
            return anyPartStartsWithAccentsInsensitive(company.Name, this.state.filter) || company.LegalNumber?.startsWith(this.state.filter);
        });
    };

    get companies(): ICompanyEntity[] {
        return isDefined(this.state.filter) ? this.getFilteredCompanies() : this.context.getData().companies;
    }

    isActiveCompany = (company: ICompanyEntity): boolean => {
        return [CompanyStateCode.Initialized].includes(company.StateCode as CompanyStateCode) && !this.state.companiesRequestedForRemoval.includes(company.Id);
    };

    isTileVisible = (el: HTMLElement) => {
        return isVisibleInContainer(this._scrollRef.current, el);
    };

    isRemoving = () => {
        return !!this.state.removingCompanies;
    };

    isArchiving = () => {
        return !!this.state.archivingCompanies;
    };

    isActionActive = () => {
        return this.isRemoving() || this.isArchiving();
    };

    handleCompanyClick = (id: number, itemRef: React.RefObject<HTMLElement>) => {
        if (this.isActionActive()) {
            return;
        }

        if (id !== this.context.getCompanyId() || this.context.getAppMode() === AppMode.OrganizationSettings) {
            this.context.setCurrentCompanyId(id);
        }

        const url = addCompanyIdToUrl(ROUTE_HOME, id);

        this.props.history.push(url);
    };

    handleCompanyChange = (id: number) => {
        if (!this._preventScroll) {
            this.scrollToCompany(id);
        }

        this._preventScroll = false;
    };

    handleFilterChange = (e: IInputOnChangeEvent) => {
        this.props.setAlert(null);
        this.setState({
            filter: e.value as string
        });
    };

    handleAdd = async () => {
        await this.context.setCurrentCompanyId(null);
        this.props.history.push(ROUTE_NEW_COMPANY);
    };

    handleRemove = async () => {
        this.props.setAlert(null);

        // wait if the removing companies are not loaded yet
        await this._companiesWaitingForRemovalPromise;

        this.setState({
            removingCompanies: this.companies.reduce((removingCompanies, company) => {
                if (this.state.companiesRequestedForRemoval.includes(company.Id)) {
                    removingCompanies[company.Id] = true;
                }

                return removingCompanies;
            }, {} as { [companyId: number]: boolean })
        });
    };

    handleArchive = () => {
        this.props.setAlert(null);
        this.setState({
            archivingCompanies: this.companies.reduce((archivingCompanies, company) => {
                if (company.StateCode === CompanyStateCode.Archived) {
                    archivingCompanies[company.Id] = true;
                }

                return archivingCompanies;
            }, {} as { [companyId: number]: boolean })
        });
    };

    handleActionConfirm = async () => {
        const batch = this.props.oData.batch();
        const entityWrapper = batch.fromPath(this._def.entitySet as string);

        this.props.setBusy(true);

        const companiesState = this.isRemoving() ? this.state.removingCompanies : this.state.archivingCompanies;
        const companies = Object.keys(companiesState);

        for (let i = 0; i < companies.length; i++) {
            const id = parseInt(companies[i]);
            batch.beginAtomicityGroup(`group${i}`);

            if (this.isRemoving()) {
                if (companiesState[id]) {
                    if (!this.state.companiesRequestedForRemoval.includes(id)) {
                        entityWrapper.delete(id);
                    }
                } else {
                    if (this.state.companiesRequestedForRemoval.includes(id)) {
                        this.restoreCompanyFromDeleteRequest(id);
                    }
                }
            } else {
                entityWrapper.update(id, {
                    [CompanyEntity.StateCode]: companiesState[id] ? CompanyStateCode.Archived : CompanyStateCode.Initialized
                });
            }
        }

        if (!batch.isEmpty()) {
            let firstErrorMessage = "";
            let errorCount = 0;
            let res;
            try {
                res = await batch.execute();

                // todo unified error parsing/handling method
                for (let i = 0; i < res.length; i++) {
                    const rowRes = res[i];

                    if (!isBatchResultOk(rowRes)) {
                        if (!firstErrorMessage) {
                            firstErrorMessage = (rowRes.body as ODataError)._message;
                        }
                        errorCount += 1;
                    }
                }
            } catch (e) {
                // common error handler (e.g. network error)
                errorCount = 1;
                firstErrorMessage = e.message;
            }

            const okCount = res ? res.length - errorCount : 0;
            const hasError = errorCount > 0;

            if (okCount > 0) {
                await this.context.updateCompanies();
            }

            const title = this.props.t(`Components:Table.${hasError ? "UpdateError" : "UpdateOk"}`);
            const subTitle = hasError ? firstErrorMessage : this.props.t(this.isRemoving() ? "Components:Table.RemovePlannedOk" : "Companies:Dashboard.ArchivationOk");

            this.props.setAlert({
                title, subTitle,
                status: hasError ? Status.Error : Status.Success,
                useFade: !hasError,
                detailData: hasError ? res : null
            });
            await this.updateCompaniesWaitingForRemoval();
        }


        this.props.setBusy(false);

        this.setState({
            removingCompanies: null,
            archivingCompanies: null
        });
        this.forceUpdate();
    };

    handleActionCancel = () => {
        this.setState({
            removingCompanies: null,
            archivingCompanies: null
        });
    };

    restoreCompanyFromDeleteRequest = async (id: number) => {
        const res = await customFetch(`${COMPANY_REMOVAL_CANCEL}/${id}`);

        if (res.ok) {
            await this.updateCompaniesWaitingForRemoval();
        }
    };

    handleCompanyActionChange = (id: number, event: React.MouseEvent) => {
        if (!this.isActionActive()) {
            const company = this.companies.find(company => company.Id === id);

            if (this.state.companiesRequestedForRemoval.includes(company.Id)) {
                // restore company clicked
                this.restoreCompanyFromDeleteRequest(id);
            }

            event.stopPropagation();

        } else {
            if (this.isRemoving()) {
                this.setState({
                    removingCompanies: {
                        ...this.state.removingCompanies,
                        [id]: !this.state.removingCompanies[id]
                    }
                });
            } else if (this.isArchiving()) {
                this.setState({
                    archivingCompanies: {
                        ...this.state.archivingCompanies,
                        [id]: !this.state.archivingCompanies[id]
                    }
                });
            }
        }
    };

    handleOurOrganizationTileClick = () => {
        if (this.context.getAppMode() !== AppMode.OrganizationSettings) {
            this.context.setAppMode(AppMode.OrganizationSettings);
        }

        this.props.history.push(ROUTE_HOME);
    };

    scrollToCompany = (companyId: number, scrollData?: IScrollData) => {
        // without setTimeout, the scroll sometimes doesn't happen
        setTimeout(() => {
            if (this._scrollRef.current) {

                if (!companyId) {
                    this._scrollRef.current.scrollTop = 0;
                    return;
                } else {
                    const item = document.querySelector(`[data-companyid="${companyId}"]`) as HTMLElement;

                    if (item && (scrollData || !this.isTileVisible(item))) {
                        if (scrollData?.from) {
                            this._scrollRef.current.scrollTop = scrollData?.from;
                        }

                        this._scrollRef.current.scrollTo({
                            top: scrollData?.to || item.offsetTop,
                            behavior: "smooth"
                        });
                    }

                    item.focus({
                        preventScroll: true
                    });
                }
            }
        });
    };

    getConfirmationText = () => {
        const count = Object.values(this.state.removingCompanies ?? this.state.archivingCompanies).filter(val => val).length;

        return getConfirmationActionText(this.props.t(`Common:General.${this.isRemoving() ? "Remove" : "Archive"}`), count);
    };

    getActionData = (company: ICompany): {
        actionIcon: React.ComponentType<IIconProps>;
        statusIcon: React.ComponentType<IIconProps>;
        title: string;
        isActionActive: boolean;
    } => {
        const isBeingRemoved = this.state.removingCompanies?.[company.Id];
        const isBeingArchived = this.state.archivingCompanies?.[company.Id];
        const isRemoveAction = this.isRemoving();
        const isArchiveAction = this.isArchiving();
        const isDeleteRequested = this.state.companiesRequestedForRemoval.includes(company.Id);
        const isArchived = company.StateCode === CompanyStateCode.Archived;
        const isNotInitialized = company.StateCode === CompanyStateCode.New;

        let icon: React.ComponentType<IIconProps> = null;
        let statusIcon: React.ComponentType<IIconProps> = null;
        let isActionActive = false;
        let title = "";

        switch (true) {
            case isBeingRemoved:
                icon = RefreshIcon;
                isActionActive = true;
                title = this.props.t("Companies:CompanyTile.Restore");
                break;
            case isBeingArchived:
                icon = ArchiveFilledIcon;
                title = this.props.t("Companies:CompanyTile.Restore");
                isActionActive = true;
                break;
            case isRemoveAction:
                icon = BinIcon;
                title = this.props.t("Companies:CompanyTile.Delete");
                break;
            case isArchiveAction:
                if (!isDeleteRequested && !isNotInitialized) {
                    icon = ArchiveIcon;
                    title = this.props.t("Companies:CompanyTile.Archive");
                }
                break;
            case isArchived:
                statusIcon = ArchiveFilledIcon;
                title = this.props.t("Companies:CompanyTile.Archived");
                break;
        }

        return {
            actionIcon: icon,
            statusIcon,
            title,
            isActionActive
        };
    };

    renderCompanyTile = (company: ICompany) => {
        const context = this.context as IAppContext;
        const isOrgSettingMode = context.getAppMode() === AppMode.OrganizationSettings;
        const currentCompanyId = context.getCompanyId();
        const isDeleteRequested = this.state.companiesRequestedForRemoval.includes(company.Id);
        const actionData = this.getActionData(company);

        return (
            <CompanyTile
                key={company.Id}
                onClick={this.handleCompanyClick}
                actionIcon={actionData.actionIcon}
                statusIcon={actionData.statusIcon}
                iconTitle={actionData.title}
                onActionClick={this.handleCompanyActionChange}
                isDisabled={actionData.isActionActive}
                isDeleteRequested={isDeleteRequested}
                isSelected={!isOrgSettingMode && company.Id === currentCompanyId}
                company={company}/>
        );
    };

    renderCompanyDashboard = () => {
        const context = this.context as IAppContext;
        const isOrgSettingMode = this.context.getAppMode() === AppMode.OrganizationSettings;
        const activeCompanies = this.companies.filter(company => this.isActiveCompany(company));
        const inactiveCompanies = this.companies.filter(company => !this.isActiveCompany(company));
        const shouldShowOurOrg = isNotDefined(this.state.filter) || anyPartStartsWithAccentsInsensitive(this.props.t(`Components:OurOrganizationTile.${getCompanySelectorTitleKey(this.context)}`), this.state.filter);
        const canManageCompanies = this.canManageCompanies;
        const tenant = context.getData().tenant;

        return (
            <ViewStyledCompanyDashboard
                hotspotContextId={HotspotViewIds.CompanyDashboard}
                className={this.props.className}
                testid={TestIds.CompanyDashboard}>
                <SmartHeaderStyled title={this.props.t("Companies:Title")} shouldHideVariant/>
                {this.props.alert}
                <Toolbar>
                    {this.isActionActive() &&
                        <ButtonGroup>
                            <ConfirmationButtons
                                confirmText={this.getConfirmationText()}
                                isDisabled={!Object.keys(this.state.removingCompanies ?? this.state.archivingCompanies).length}
                                onConfirm={this.handleActionConfirm}
                                onCancel={this.handleActionCancel}/>
                        </ButtonGroup>
                    }
                    <WriteLineWrapper>
                        <WriteLine value={this.state.filter}
                                   width="240px"
                                   placeholder={this.props.t("Companies:Dashboard.Search")}
                                   textAlign={TextAlign.Left}
                                   onChange={this.handleFilterChange}
                                   withClearButton/>
                    </WriteLineWrapper>
                    <ButtonGroup>
                        <IconButton title={this.props.t("Common:General.Remove")} isTransparent
                                    hotspotId={"removeCompany"}
                                    isActive={this.isRemoving()}
                                    isDisabled={this.isArchiving() || !canManageCompanies || tenant.ProductCode === ProductCode.EvalaForCompanies}
                                    onClick={this.handleRemove}>
                            <BinIcon/>
                        </IconButton>

                        {this.hasReachedCompanyLimit &&
                            <IconButton title={this.props.t("Common:General.Archive")} isTransparent
                                        hotspotId={"archiveCompany"}
                                        isActive={this.isArchiving()}
                                        isDisabled={this.isRemoving() || !canManageCompanies}
                                        onClick={this.handleArchive}>
                                <ArchiveIcon/>
                            </IconButton>
                        }

                        <IconButton title={this.props.t("Common:General.Add")}
                                    hotspotId={"addCompany"}
                                    isDisabled={this.isActionActive() || !canManageCompanies || !this.hasReachedCompanyLimit}
                                    onClick={this.handleAdd}>
                            <AddIcon isLight/>
                        </IconButton>
                    </ButtonGroup>
                </Toolbar>
                <ScrollBar primary
                           scrollableNodeProps={{
                               ref: this._scrollRef
                           }}
                           style={{ overflowX: "hidden", position: "relative" }}>
                    <DashboardBody
                        $isWithoutPadding={true}
                        ref={this._bodyRef}>
                        {shouldShowOurOrg &&
                            <OurOrganizationTile isSelected={isOrgSettingMode}
                                                 onClick={this.handleOurOrganizationTileClick}/>
                        }

                        {activeCompanies.map((company: ICompany, i: number) => this.renderCompanyTile(company))}
                        {!!inactiveCompanies.length && <Separator style={{ width: "100%" }}/>}
                        {inactiveCompanies.map((company: ICompany, i: number) => this.renderCompanyTile(company))}
                        {activeCompanies.length === 0 && inactiveCompanies.length === 0 && !shouldShowOurOrg && this.props.t("Companies:Dashboard.NoCompanyFound")}
                    </DashboardBody>
                </ScrollBar>
            </ViewStyledCompanyDashboard>
        );
    };

    render() {
        if (!this.props.tReady) {
            return null;
        }
        return this.renderCompanyDashboard();
    }
}

export default withTranslation(getDefinitions.translationFiles)(withOData(withRouter(withPermissionContext(withBusyIndicator()(withAlert({
    position: AlertPosition.CenteredBottom
})(CompanyDashboard))))));
