import { ifAny, IGetValueArgs } from "@components/smart/FieldInfo";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { AssetDisposalTypeCode, AssetItemTypeCode, CbaAssetDisposalTypeCode } from "@odata/GeneratedEnums";
import { getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import { getCompanyCurrency, isAccountAssignmentCompany } from "@utils/CompanyUtils";
import i18next from "i18next";

import { BasicInputSizes, FieldType, NavigationSource, TextAlign, ValidatorType } from "../../../enums";
import { TValue } from "../../../global.types";
import BindingContext from "../../../odata/BindingContext";
import CurrencyType from "../../../types/Currency";
import { currencyScaleFormatter } from "../../../types/Number";
import { IFormDef } from "../../../views/formView/Form";
import { defaultDateFromActiveFiscalYear } from "../../../views/formView/Form.utils";
import { FormStorage } from "../../../views/formView/FormStorage";
import { getSingleAccountFieldDef } from "../../accountAssignment/AccountAssignment.utils";
import { DOCUMENT_DATE_CHANGE_DEBOUNCE } from "../../documents/DocumentDef";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import { getInActiveFYValidation, getSameOrLaterItemOfTypeValidation, getValidationResult } from "../Asset.utils";
import {
    DamageCompensationAmountPath,
    DisposalAccountPath,
    DisposalDatePath,
    DisposalReasonPath,
    IFixedAssetDisposalCustomData
} from "./FixedAsset.utils";
import { IFixedAssetDisposalDialogEntity } from "./FixedAssetDisposalDialogFormView";

export function getAccountPrefixByDisposalType(reason: AssetDisposalTypeCode): string {
    switch (reason) {
        case AssetDisposalTypeCode.Sold:
            return "541";
        case AssetDisposalTypeCode.Disposed:
            return "551";
        case AssetDisposalTypeCode.Damaged:
            return "549";
        case AssetDisposalTypeCode.TransferredFreeOfCharge:
            return "543";
        case AssetDisposalTypeCode.TransferredToPersonalUse:
            return "491";
    }
}

const isOtherDamage = (args: IGetValueArgs) => {
    const reason = args.storage.getValueByPath(DisposalReasonPath) as CbaAssetDisposalTypeCode;
    return reason === CbaAssetDisposalTypeCode.OtherDamage;
};

export const getDefinition: IGetDefinition = (context): IDefinition => {
    const hasAccounting = isAccountAssignmentCompany(context);
    const DisposalAccount = getSingleAccountFieldDef({ datePath: "DisposalDate" });
    DisposalAccount.fieldSettings.localDependentFields
        .forEach(ldf => {
            ldf.to = { id: BindingContext.localContext(`DisposalAccount_${ldf.to.id}`) };
            ldf.navigateFrom = NavigationSource.Parent;
        });

    const form: IFormDef = {
        id: "FixedAssetDisposalSpecialForm",
        translationFiles: getDefinition.translationFiles,
        fieldDefinition: {
            [DisposalDatePath]: {
                type: FieldType.Date,
                label: i18next.t("FixedAsset:Disposal.DisposalDate"),
                fieldSettings: {
                    debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                },
                isRequired: true,
                validator: {
                    type: ValidatorType.Date,
                    settings: {
                        customValidator: (value, args) => {
                            const storage = (args.storage as FormStorage<IFixedAssetDisposalDialogEntity, IFixedAssetDisposalCustomData>).getCustomData().assetStorage;
                            const argsWithAssetStorage = { ...args, storage };
                            return getValidationResult(
                                getInActiveFYValidation(value, args),
                                getSameOrLaterItemOfTypeValidation(value, argsWithAssetStorage, [
                                    AssetItemTypeCode.TechnicalImprovement,
                                    AssetItemTypeCode.IncreaseOfPrice,
                                    AssetItemTypeCode.ReductionOfPrice,
                                    AssetItemTypeCode.PutInUse
                                ])
                            );
                        }
                    }
                },
                defaultValue: defaultDateFromActiveFiscalYear
            },
            [DisposalReasonPath]: {
                type: FieldType.ComboBox,
                label: i18next.t("FixedAsset:Disposal.Reason"),
                fieldSettings: {
                    items: getEnumSelectItems(hasAccounting ? EntityTypeName.AssetDisposalType : EntityTypeName.CbaAssetDisposalType)
                },
                isRequired: true,
                width: BasicInputSizes.L,
                defaultValue: hasAccounting ? AssetDisposalTypeCode.Disposed : CbaAssetDisposalTypeCode.PhysicalDisposal
            },
            [DamageCompensationAmountPath]: {
                type: FieldType.NumberInput,
                label: i18next.t("FixedAsset:Disposal.DamageCompensationAmount"),
                textAlign: TextAlign.Right,
                isRequired: !hasAccounting,
                validator: {
                    type: ValidatorType.Number,
                    settings: {
                        min: 0
                    }
                },
                fieldSettings: {
                    formatter: currencyScaleFormatter,
                    unit: CurrencyType.getCurrencyUnit(getCompanyCurrency(context))
                },
                isVisible: ifAny(hasAccounting, isOtherDamage)
            },
            [DisposalAccountPath]: {
                ...DisposalAccount,
                label: i18next.t("FixedAsset:Disposal.DisposalAccount"),
                isRequired: true,
                filter: {
                    select: (args: IGetValueArgs) => {
                        const reason = args.storage.getValueByPath(DisposalReasonPath) as AssetDisposalTypeCode;
                        const num = getAccountPrefixByDisposalType(reason);
                        return `IsActive eq true AND startsWith(Number,'${num}')`;
                    }
                },
                formatter: (val: TValue, args?: IFormatOptions): string => {
                    const name = args.storage.getValueByPath(BindingContext.localContext("DisposalAccount_Name"));
                    const number = args.storage.getValueByPath(BindingContext.localContext("DisposalAccount_Number"));
                    return val ? `${number ?? ""} - ${name ?? ""}` : "";
                },
                width: BasicInputSizes.L
            }
        },
        groups: [
            {
                id: "Common",
                showGroupDivider: false,
                rows: [
                    [{ id: DisposalDatePath }, { id: DisposalReasonPath }]
                ]
            }
        ]
    };

    form.groups[0].rows.push(hasAccounting ? [{ id: DisposalAccountPath }] : [{ id: DamageCompensationAmountPath }]);

    return {
        form
    };
};

getDefinition.translationFiles = ["FixedAsset", "Common"];
