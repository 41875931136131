import React from "react";
import TestIds from "../../testIds";
import { FileStripFooter, StyledFileStrip } from "./FileStrip.styles";
import FileStripItem from "./FileStripItem";
import { WithTranslation, withTranslation } from "react-i18next";
import { FolderAddIcon } from "../icon";
import {
    FileStripItemSize,
    FileStripSizeConfig,
    getFileStripConfig,
    getFileStripSizeForSpace,
    IFileStripItem,
    MAX_FILE_STRIP_CONFIG
} from "./FileStrip.utils";
import CustomResizeObserver from "../customResizeObserver";
import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import { ScrollBar } from "../scrollBar";
import SimpleBar from "simplebar-react";
import FocusManager, { FocusDirection } from "../focusManager";
import { composeRefHandlers } from "@utils/general";
import { Button } from "../button";
import { calcCssProp } from "@utils/dom.utils";

export interface IFileStripProps extends WithTranslation, WithDomManipulator {
    items: IFileStripItem[];
    selectedId?: string;
    onSelectionChange?: (item: IFileStripItem) => void;
    isDragging?: boolean;
    dragOverId?: string;
    onDrop?: (item: IFileStripItem, event: React.DragEvent) => void;
    onDragEnter?: (id: string) => void;
    onDragLeave?: (id: string) => void;
    onResize?: (width: number) => void;
    onAddItems?: () => void;
    className?: string;
}

interface IState {
    size?: FileStripItemSize;
    width?: number;
}

class FileStrip extends React.PureComponent<IFileStripProps, IState> {
    #footerRef = React.createRef<HTMLDivElement>();
    #ref = React.createRef<HTMLDivElement>();
    #simpleBarRef = React.createRef<SimpleBar>();

    state: IState = {
        size: FileStripItemSize.M
    };

    componentDidMount() {
        this.recalculateSize();
    }

    componentDidUpdate(prevProps: Readonly<IFileStripProps>, prevState: Readonly<IState>) {
        if (this.state.size !== prevState.size || this.state.width !== prevState.width) {
            // state is changed during recalculation, just notify parent about size change
            this.props.onResize?.(this.state.width);
            this.#simpleBarRef.current.recalculate();
        } else {
            this.recalculateSize();
        }
    }

    get config() {
        return getFileStripConfig(window.innerWidth);
    }

    /**
     * Recalculates space for items according to width of the screen, height of the FileStrip and item count
     */
    recalculateSize() {
        this.props.domManipulatorOrchestrator.registerCallback<{ config: FileStripSizeConfig, paddingTop: number, offsetTop: number }>(
            () => (this.#ref.current && {
                config: this.config,
                paddingTop: calcCssProp(this.#ref.current, "padding-top"),
                offsetTop: this.#footerRef.current?.offsetTop
            }),
            (params) => {
                if (params) {
                    const { config, offsetTop, paddingTop } = params;
                    let { width } = config;
                    const horizontalSpace = width - 2 * config.padding;

                    const height = offsetTop - paddingTop;
                    // last item has no margin, margin is only between items
                    const verticalSpace = (height / this.props.items.length);
                    const size = getFileStripSizeForSpace(horizontalSpace, verticalSpace);
                    if (size === FileStripItemSize.S) {
                        width = MAX_FILE_STRIP_CONFIG.width;
                    }
                    if (size !== this.state.size || this.state.width !== width) {
                        this.setState({ size, width });
                    }
                }
            },
            [this.#ref, this.#footerRef]
        );
    }

    getItem(id: string): IFileStripItem {
        return this.props.items.find(item => item.id === id);
    }

    handleResize = (): void => {
        this.recalculateSize();
    };

    handleSelect = (id: string): void => {
        this.props.onSelectionChange?.(this.getItem(id));
    };

    handleDrop = (id: string, event: React.DragEvent) => {
        this.props.onDrop?.(this.getItem(id), event);
    };

    render() {
        const { addHasIcon, width } = this.config;
        return (
            <FocusManager direction={FocusDirection.Vertical}>
                {({ itemProps, wrapperProps }) => (
                    <StyledFileStrip {...wrapperProps}
                                     ref={composeRefHandlers(this.#ref, wrapperProps.ref)}
                                     _width={this.state.width ?? width}
                                     data-testid={TestIds.FileStrip}>
                        <ScrollBar
                            ref={this.#simpleBarRef}
                            style={{
                                overflowX: "hidden",
                                zIndex: 2 /* display over splitLayout splitter */
                            }}>
                            {this.props.items.map(item => (
                                <FileStripItem key={item.id}
                                               {...item}
                                               onSelect={this.handleSelect}
                                               isDragging={this.props.isDragging}
                                               isDragOver={this.props.dragOverId === item.id}
                                               onDragEnter={this.props.onDragEnter}
                                               onDragLeave={this.props.onDragLeave}
                                               onDrop={this.handleDrop}
                                               size={this.state.size}
                                               isSelected={this.props.selectedId === item.id}
                                               passProps={itemProps}/>
                            ))}
                        </ScrollBar>
                        <FileStripFooter ref={this.#footerRef}>
                            {this.props.onAddItems &&
                                    <Button icon={addHasIcon ? <FolderAddIcon/> : null}
                                            onClick={this.props.onAddItems}
                                            isTransparent>
                                        {this.props.t("Components:FileStrip.Add")}
                                    </Button>}
                        </FileStripFooter>
                        <CustomResizeObserver includeWindowResize onResize={this.handleResize}/>
                    </StyledFileStrip>
                )}
            </FocusManager>
        );
    }
}

export default withTranslation(["Components"])(withDomManipulator(FileStrip));
