import BindingContext from "@odata/BindingContext";
import { AccountEntity, IEntityBase } from "@odata/GeneratedEntityTypes";
import { AccountCategoryCode } from "@odata/GeneratedEnums";

export interface IOnVatLockOverrideConfirmDialogFinish {
    date: Date;
    accountNumber: string;
}

export const VatLockOverrideConfirmDialogPath = BindingContext.localContext("VatLockOverrideConfirmDialog");
export const VatLockOverrideDatePath = "##DateAccountingTransaction##";
export const VatLockOverrideAccountPath = "##Account##";

export interface IVatLockOverrideDialogEntity extends IEntityBase {
    [VatLockOverrideDatePath]?: Date;
    [VatLockOverrideAccountPath]?: string;
}

export const getBalanceSheetAccountsFilter = (): string => {
    return `${AccountEntity.CategoryCode} eq '${AccountCategoryCode.BalanceSheet}'`;
};