import { isDefined } from "./general";

/**
 * Function returns true if @param element is completely visible in @param container
 * @param container
 * @param element
 */
export function isVisibleInContainer(container: HTMLElement, element: HTMLElement): boolean {
    const viewportTop = container.scrollTop;
    const viewportBottom = viewportTop + container.offsetHeight;
    const itemTop = element.offsetTop;
    const itemBottom = itemTop + element.offsetHeight;
    return viewportTop <= itemTop && viewportBottom >= itemBottom;
}

/**
 * Scrolls element into container according to options. Difference from build-in scrollIntoView is that different
 * scrollable container is used than body element
 * Todo: horizontal positioning if needed?
 * @param container
 * @param element
 * @param options
 * @param offset
 */
export function scrollIntoView(container: HTMLElement, element: HTMLElement, options: ScrollIntoViewOptions, offset?: { top?: number, left?: number }): void {
    const { offsetTop, offsetHeight } = element;
    let top = offsetTop, left = 0;

    top += offset?.top ?? 0;
    left += offset?.left ?? 0;

    // todo: nearest
    if (options.block === "end") {
        top -= container.offsetHeight + offsetHeight;
    }
    if (options.block === "center") {
        top -= (container.offsetHeight - offsetHeight) / 2;
    }

    const opts: ScrollToOptions = { top, left };
    if (options.behavior) {
        opts.behavior = options.behavior;
    }
    container.scrollTo(opts);
}

export function scrollIntoViewIfNeeded(container: HTMLElement, element: HTMLElement, options: ScrollIntoViewOptions): void {
    if (!isVisibleInContainer(container, element)) {
        scrollIntoView(container, element, options);
    }
}

export function joinReactNodes(nodes: React.ReactNode[], separator: string): React.ReactNode {
    return nodes.reduce((prev, curr) => (isDefined(curr) && isDefined(prev) ? [prev, separator, curr] : (isDefined(prev) ? prev : curr)), null);
}

/**
 * Return real numeric value for element property (we mostly need this for calculations, so it returns number)
 * @param element
 * @param propName
 */
export function calcCssProp(element: HTMLElement, propName: string): number {
    return parseInt(window.getComputedStyle(element, null).getPropertyValue(propName));
}

/**
 * detect Safari browser. Note: vendor is deprecated property, but used. Detecting only by "safari" in userAgent is
 * not reliable as many other browsers have such a string in their userAgent
 */
export function isSafariBrowser(): boolean {
    return navigator.vendor === "Apple Computer, Inc." && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

/**
 * Forces repaint the element in the DOM. E.g. when element becomes visible and Safari stuck with
 * rendering it from shadow DOM again.
 * @param el
 */
export function forceRepaintElement(el: HTMLElement): void {
    // define global force-repaint class once
    const styleId = "force-repaint-style";
    const cssClass = "force-repaint";
    if (!document.getElementById(styleId)) {
        const style = document.createElement("style");
        style.id = styleId;
        style.innerHTML = `.${cssClass} { transform: translateZ(0px); }`;
        document.head.appendChild(style);
    }
    el.classList.toggle(cssClass);
}