import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { fetchItemsByInfo } from "@components/smart/smartSelect/SmartSelectAPI";
import { IAccountEntity, IElectronicSubmissionEntity } from "@odata/GeneratedEntityTypes";
import { getChoAIdBasedOnDate, loadAccountsBasedOnDate } from "@pages/accountAssignment/AccountAssignment.utils";
import { isMatchingAccount } from "@pages/documents/Document.utils";
import {
    getBalanceSheetAccountsFilter,
    IVatLockOverrideDialogEntity,
    VatLockOverrideAccountPath,
    VatLockOverrideDatePath
} from "@pages/electronicSubmission/VatLockOverrideConfirmDialog.utils";

import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { AwaitedArray, toTuple } from "../../global.types";
import { IContextInitArgs } from "../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";

interface IProps extends IFormViewProps<IVatLockOverrideDialogEntity> {
    previousSubmission?: IElectronicSubmissionEntity;
    showAccountField?: boolean;
}

class VatLockOverrideConfirmDialogFormView extends FormViewForExtend<IVatLockOverrideDialogEntity, IProps> {
    chartOfAccountsId: number = null;

    getAdditionalLoadPromise = (args: IContextInitArgs) => {
        if (!this.props.showAccountField) {
            return toTuple([]);
        }

        return toTuple([
            loadAccountsBasedOnDate(this.props.storage, this.entity[VatLockOverrideDatePath], getBalanceSheetAccountsFilter()) as Promise<IAccountEntity[]>
        ]);
    };

    getAdditionalResults(): AwaitedArray<ReturnType<VatLockOverrideConfirmDialogFormView["getAdditionalLoadPromise"]>> {
        return this.props.storage.data.additionalResults;
    }

    onAfterLoad(): Promise<void> {
        const isAccountUsed = this.props.storage.data.definition?.groups[0]?.rows.find(row => row.find(field => field.id === VatLockOverrideAccountPath));

        if (isAccountUsed) {
            const defaultAccountNumber = "349";
            const loadedAccounts = this.getAdditionalResults()[0];
            const defaultAccount = this.props.previousSubmission.VatStatementDocument?.AccountAssignmentSelection?.AccountAssignment?.DebitAccount?.Id ?? loadedAccounts.find(a => a.Number === defaultAccountNumber)?.Id;

            this.props.storage.setValue(this.props.storage.data.bindingContext.navigate(VatLockOverrideAccountPath), defaultAccount);
            this.chartOfAccountsId = getChoAIdBasedOnDate(this.props.storage, this.entity[VatLockOverrideDatePath]);
        }

        return super.onAfterLoad();
    }

    handleAccountChange = (e: ISmartFieldChange): void => {
        this.props.storage.setValue(e.bindingContext, e.value);
        this.props.storage.refreshFields();
    };

    handleDateChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() !== VatLockOverrideDatePath || !e.triggerAdditionalTasks || !this.props.showAccountField) {
            return;
        }

        const date = e.value as Date;
        const oldCoA = this.chartOfAccountsId;
        const newCoA = getChoAIdBasedOnDate(this.props.storage, date);

        if (oldCoA === newCoA) {
            return;
        }

        this.props.storage.setBusy(true);

        const dateFieldInfo = this.props.storage.getInfo(this.props.storage.data.bindingContext.navigate(VatLockOverrideAccountPath));
        const currentAccount = dateFieldInfo.fieldSettings.items.find(item => item.id === this.entity[VatLockOverrideAccountPath])?.additionalData as IAccountEntity;
        const accountsItems = await fetchItemsByInfo(this.props.storage, dateFieldInfo);
        const matchingAccountItem = accountsItems.find(item => isMatchingAccount(item.additionalData, currentAccount));

        dateFieldInfo.fieldSettings.items = accountsItems;
        this.props.storage.setValue(this.props.storage.data.bindingContext.navigate(VatLockOverrideAccountPath), matchingAccountItem?.additionalData.Id ?? null);
        this.chartOfAccountsId = newCoA;

        this.props.storage.setBusy(false);
    };

    handleChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === VatLockOverrideAccountPath) {
            // use manual handling to prevent the select from trying to set nested local dependent fields which we don't have and don't use here
            this.handleAccountChange(e);
            return;
        }

        this.handleDateChange(e);

        return super.handleChange(e);
    };
}

export default withPermissionContext(VatLockOverrideConfirmDialogFormView);
