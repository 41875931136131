import styled from "styled-components/macro";

import { FIELD_VER_MARGIN, FIELD_VER_MARGIN_SMALL, StyledField, StyledFieldContent } from "./Field.styles";

export const StyledFieldsWrapper = styled.div<{
    $isColumn?: boolean;
    $smallerGap?: boolean;
    $isWithoutWrap?: boolean;
}>`
    // use gap instead of margin on field + negative margin here 
    row-gap: ${props => props.$smallerGap ? FIELD_VER_MARGIN_SMALL : FIELD_VER_MARGIN};
    display: flex;
    flex-direction: ${props => props.$isColumn ? "column" : "row"};
    flex-wrap: ${props => props.$isWithoutWrap ? "nowrap" : "wrap"};
    align-items: ${props => props.$isColumn ? "flex-start" : "flex-end"};
    flex-grow: 1;

    & > *:last-child {
        margin-right: 0;

        & > ${StyledFieldContent} {
            padding-right: 0;
        }

        & > ${StyledField}:last-child { // if last child is grouped field
            margin-right: 0;
        }
    }

    // when all fields are invisible, empty wrapper renders extra space there -> hide it also
    &:empty,
    &:not(:has(> *:not(:empty))) {
        display: none;
    }
`;