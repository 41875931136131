import Dialog from "@components/dialog/Dialog";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import BindingContext, { createBindingContext } from "@odata/BindingContext";
import { IAccountEntity, IElectronicSubmissionEntity } from "@odata/GeneratedEntityTypes";
import { WithOData, withOData } from "@odata/withOData";
import { getSingleAccountFieldDef } from "@pages/accountAssignment/AccountAssignment.utils";
import { dateAccountingCustomValidator } from "@pages/documents/DocumentDef";
import {
    getBalanceSheetAccountsFilter,
    IOnVatLockOverrideConfirmDialogFinish,
    IVatLockOverrideDialogEntity,
    VatLockOverrideAccountPath,
    VatLockOverrideConfirmDialogPath,
    VatLockOverrideDatePath
} from "@pages/electronicSubmission/VatLockOverrideConfirmDialog.utils";
import VatLockOverrideConfirmDialogFormView from "@pages/electronicSubmission/VatLockOverrideConfirmDialogFormView";
import { IDefinition, IGetDefinitionOptionalTranslation } from "@pages/PageUtils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, ValidatorType, ValueType } from "../../enums";
import { TValue } from "../../global.types";
import { getUtcDate } from "../../types/Date";
import { FormViewForExtend } from "../../views/formView/FormView";
import PlugAndPlayForm from "../../views/formView/PlugAndPlayForm";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";

interface IProps extends WithTranslation, WithOData {
    onFinish: (result: IOnVatLockOverrideConfirmDialogFinish) => void;
    title: string;
    dateFieldLabel: string;
    showAccountField?: boolean;
    previousSubmission?: IElectronicSubmissionEntity;
}


class VatLockOverrideConfirmDialog extends React.PureComponent<IProps> {
    _formRef = React.createRef<FormViewForExtend<IVatLockOverrideDialogEntity>>();

    handleConfirm = async () => {
        const storage = this._formRef.current.props.storage;
        await storage.validateAll();
        storage.refresh();

        if (!storage.isAnyError()) {
            const entity = storage.data.entity;
            let accountNumber: string = null;

            if (this.props.showAccountField) {
                const accountFieldInfo = storage.getInfo(storage.data.bindingContext.navigate(VatLockOverrideAccountPath));
                const accountItem = accountFieldInfo.fieldSettings.items.find(item => item.id === entity[VatLockOverrideAccountPath]);

                accountNumber = (accountItem?.additionalData as IAccountEntity)?.Number;
            }

            this.props.onFinish({
                date: entity[VatLockOverrideDatePath],
                accountNumber: accountNumber
            });
        }
    };

    handleCancel = () => {
        this.props.onFinish(null);
    };

    getBindingContext = (): BindingContext => {
        return createBindingContext(VatLockOverrideConfirmDialogPath, this.props.oData.getMetadata());
    };

    renderButtons = () => {
        return (
            <ConfirmationButtons onConfirm={this.handleConfirm}
                                 onCancel={this.handleCancel}
                                 confirmText={this.props.t("Common:General.Confirm")}/>
        );
    };

    render() {
        return (
            <Dialog
                title={this.props.title}
                onConfirm={this.handleConfirm}
                onClose={this.handleCancel}
                footer={this.renderButtons()}
            >
                <PlugAndPlayForm
                    getDef={getDefinition(this.props.dateFieldLabel, !!this.props.showAccountField)}
                    t={this.props.t}
                    bindingContext={this.getBindingContext()}
                    formRef={this._formRef}
                    formView={VatLockOverrideConfirmDialogFormView}
                    formViewProps={{
                        formProps: {
                            isSimple: true,
                            renderScrollbar: false
                        },
                        isInDialog: true,
                        // @ts-ignore
                        previousSubmission: this.props.previousSubmission,
                        showAccountField: this.props.showAccountField
                    }}
                />
            </Dialog>
        );
    }
}

const getDefinition = (dateLabel: string, showAccountField: boolean): IGetDefinitionOptionalTranslation => {
    return (context: IAppContext) => {
        const accountFieldDef = getSingleAccountFieldDef({
            datePath: VatLockOverrideDatePath,
            fallbackToActiveChOA: true
        });

        const def: IDefinition = {
            entitySet: VatLockOverrideConfirmDialogPath,
            form: {
                id: "VatLockOverrideConfirmDialogSpecialForm",
                groups: [{
                    id: "Main",
                    rows: [
                        [{ id: VatLockOverrideDatePath }]
                    ]
                }],
                fieldDefinition: {
                    [VatLockOverrideDatePath]: {
                        width: BasicInputSizes.M,
                        label: dateLabel,
                        isRequired: true,
                        type: FieldType.Date,
                        valueType: ValueType.Date,
                        validator: {
                            type: ValidatorType.Custom,
                            settings: {
                                customValidator: (value: TValue, args: IGetValueArgs) => {
                                    return dateAccountingCustomValidator(value, args, true);
                                }
                            }
                        },
                        defaultValue: getUtcDate()
                    },
                    [VatLockOverrideAccountPath]: {
                        ...accountFieldDef,
                        width: BasicInputSizes.XL,
                        label: i18next.t("ElectronicSubmission:VatLockOverrideDialog.Account"),
                        isRequired: true,
                        fieldSettings: {
                            ...accountFieldDef.fieldSettings,
                            preloadItems: true
                        },
                        filter: {
                            select: () => {
                                return getBalanceSheetAccountsFilter();
                            }
                        }
                    }
                },
                translationFiles: ["Document"]
            }
        };

        if (showAccountField && !isCashBasisAccountingCompany(context)) {
            def.form.groups[0].rows.push([{ id: VatLockOverrideAccountPath }]);
        }

        return def;
    };
};

export default withTranslation(["Document", "Common", "ElectronicSubmission"])(withOData(VatLockOverrideConfirmDialog));